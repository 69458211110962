const logoApi =[
    {
        id: 9,
        previewImages:[
            {
                id:1,
                logo_img:require('../../assets/images/logo-detailimg-1.png') ,
            },
            {
                id:2,
                logo_img:require('../../assets/images/logo-detailimg-2.png') ,
            },
            {
                id:3,
                logo_img:require('../../assets/images/logo-detailimg-3.png') ,
            },
            {
                id:4,
                logo_img:require('../../assets/images/logo-detailimg-4.png') ,
            },
        ]
    },
        
    
    {
        id: 10,
        previewImages:[
            {
                id:1,
                logo_img:require('../../assets/images/logo2-detailimg-1.png') ,
            },
            {
                id:2,
                logo_img:require('../../assets/images/logo2-detailimg-2.png') ,
            },
            {
                id:3,
                logo_img:require('../../assets/images/logo2-detailimg-3.png') ,
            },
            {
                id:4,
                logo_img:require('../../assets/images/logo2-detailimg-4.png') ,
            },
        ]
    },
    {
        id : 27,
        casename: 'hous',
        previewImages:[
            {
                id:1,
                logo_img:require('../../assets/images/hous-logo-img1.png') ,
            },
            {
                id:2,
                logo_img:require('../../assets/images/hous-logo-img2.png') ,
            },
            {
                id:3,
                logo_img:require('../../assets/images/hous-logo-img3.png') ,
            },
            {
                id:4,
                logo_img:require('../../assets/images/hous-logo-img4.png') ,
            }
        ]
    },
    {
        id : 28,
        casename: 'lernezy',
        previewImages:[
            {
                id:1,
                logo_img:require('../../assets/images/lerenzy-logoimg1.png') ,
            },
            {
                id:2,
                logo_img:require('../../assets/images/lerenzy-logoimg2.png') ,
            },
            {
                id:3,
                logo_img:require('../../assets/images/lerenzy-logoimg3.png') ,
            },
            {
                id:4,
                logo_img:require('../../assets/images/lerenzy-logoimg4.png') ,
            },
        ]
    },
    {
        id : 29,
        casename: 'shft-in',
        previewImages:[
            {
                id:1,
                logo_img:require('../../assets/images/shftin-logoimg1.png') ,
            },
            {
                id:2,
                logo_img:require('../../assets/images/shftin-logoimg2.png') ,
            },
            {
                id:3,
                logo_img:require('../../assets/images/shftin-logoimg3.png') ,
            },
            {
                id:4,
                logo_img:require('../../assets/images/shftin-logoimg4.png') ,
            }
        ]
    },
    {
        id : 30,
        casename: 'royal-bookings',
        previewImages:[
            {
                id:1,
                logo_img:require('../../assets/images/royalbooking-logoimg1.png') ,
            },
            {
                id:2,
                logo_img:require('../../assets/images/royalbooking-logoimg2.png') ,
            },
            {
                id:3,
                logo_img:require('../../assets/images/royalbooking-logoimg3.png') ,
            },
            {
                id:4,
                logo_img:require('../../assets/images/royalbooking-logoimg4.png') ,
            },
        ]
    },
    {
        id : 31,
        casename: 'whopme',
        previewImages:[
            {
                id:1,
                logo_img:require('../../assets/images/whopme-logoimg1.png') ,
            },
            {
                id:2,
                logo_img:require('../../assets/images/whopme-logoimg2.png') ,
            },
            {
                id:3,
                logo_img:require('../../assets/images/whopme-logoimg3.png') ,
            },
            {
                id:4,
                logo_img:require('../../assets/images/whopme-logoimg4.png') ,
            },
        ]
    },
    {
        id : 32,
        casename: 'e-liquid',
        previewImages:[
            {
                id:1,
                logo_img:require('../../assets/images/eliquid-logoimg1.png') ,
            },
            {
                id:2,
                logo_img:require('../../assets/images/eliquid-logoimg2.png') ,
            },
            {
                id:3,
                logo_img:require('../../assets/images/eliquid-logoimg3.png') ,
            },
            {
                id:4,
                logo_img:require('../../assets/images/eliquid-logoimg4.png') ,
            },
        ]
    },
    {
        id : 33,
        casename: 'yihills',
        previewImages:[
            {
                id:1,
                logo_img:require('../../assets/images/yihills-logoimg1.png') ,
            },
            {
                id:2,
                logo_img:require('../../assets/images/yihills-logoimg2.png') ,
            },
            {
                id:3,
                logo_img:require('../../assets/images/yihills-logoimg3.png') ,
            },
            {
                id:4,
                logo_img:require('../../assets/images/yihills-logoimg4.png') ,
            },
        ]
    },
]

export default logoApi