import React, { useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import CounterAnimation from '../animation/Counter_animation';
import ContactSection from './ContactSection';
import Motion from "../animation/Motion";

const About = () => {

    const[activeTab, setactiveTab] = useState(0)

    const handleactiveClick = (index) => {
        setactiveTab(index)
    }
    
    const items = [
        { imageSrc: require("../../assets/images/octalogix.svg").default, altText: 'Octalogix Logo' },
        { imageSrc: require("../../assets/images/byte-logo.svg").default, altText: 'Byte Logo' },
        { imageSrc: require("../../assets/images/image 93.svg").default, altText: 'Image 93' }
    ];

  return (
    <>
        <section className="about-banner">
            <div className="container-fluid">
                <div className='row align-items-center'>
                    <div className='col-lg-6'>
                        <Motion classNames="transition-none" transition={{ duration: 0.4  }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className="main-heading">
                                    <h2 className='text-light-grey'>About Terminal Tree</h2>
                                </div>
                        </Motion>
                        <Motion classNames="transition-none" transition={{ duration: 0.4 , delay: .2}} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div className="mb-5 py-3 ">
                                <p className="font-sofia">
                                    We are more than just an IT services provider - we are your partners in bringing digital transformation to your business. Founded in 2018, our company is a globally recognized, IT Solutions company located in Lahore, Pakistan.
                                </p>
                                <p className="font-sofia">
                                    Our range of services spans across various dimensions of Information Technology, including managed IT services, Web development, cloud solutions, software development, data analytics, and IT consulting. Our solutions are designed to be scalable, robust, and secure to support the complex business operations of our clients. Building a project with Terminal Tree Our mission is to empower businesses to harness the potential of technology and innovation, to navigate the digital era successfully.
                                </p>
                            </div>
                        </Motion>
                    </div>
                    <div className='col-lg-6 col-md-8 mx-auto'>
                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div>
                                <img src={require('../../assets/images/about-us-map.png')} className='w-100' alt='img' />
                            </div>
                        </Motion>
                    </div>
                </div>

                <div className="mt-5 customer-counter">
                    <div className="row">
                        <div className="col-6 col-sm-3">
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className="text-md-start text-center about-counter">
                                    <h2 className='mb-0'><div className='d-flex justify-content-md-start justify-content-center'><CounterAnimation limit={500} step={10}/>+</div></h2> 
                                    <p className='text-light-grey fw-semibold'>Happy Customers</p>
                                </div>
                            </Motion>
                        </div>
                        <div className="col-6 col-sm-3">
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className="about-counter  text-center">
                                    <h2 className='mb-0'><div className='d-flex justify-content-center'><CounterAnimation limit={200} step={5}/>+</div></h2> 
                                    <p className='text-light-grey fw-semibold'>Live Projects</p>
                                </div>
                            </Motion>
                        </div>
                        <div className="col-6 col-sm-3">
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className="about-counter  text-center">
                                <h2 className='mb-0'><div className='d-flex justify-content-center'><CounterAnimation limit={10}  step={1}/>+</div></h2>
                                    <p className='text-light-grey fw-semibold'>Years In Industry</p>
                                </div>
                            </Motion>
                        </div>
                        <div className="col-6 col-sm-3">
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className="text-md-end text-center ">
                                    <h2 className='mb-0'><div className='d-flex justify-content-md-end justify-content-center'><CounterAnimation limit={90} step={1}/>%</div></h2>
                                    <p className='text-light-grey fw-semibold'>Retention Rate</p>
                                </div>
                            </Motion>
                        </div>
                    </div>
                </div>
            </div>
        </section>

            <section className="vision">
                <div className="container-fluid">
                    <div className="row align-items-center position-relative">
                        <div className="col-lg-6">
                            <div className="">
                                <div className="mt-4">
                                    <div className="vision-block">
                                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                            <div className=' main-heading d-flex justify-content-between mb-5 pb-2 flex-wrap flex-sm-nowrap'>
                                                <h2 onClick={()=> handleactiveClick(0)} className={activeTab === 0 ? "text-light-blue" : '' } >Mission</h2>
                                                <h2 onClick={()=> handleactiveClick(1)} className={activeTab === 1 ? "text-light-blue" : '' } >Vision</h2>
                                                <h2 onClick={()=> handleactiveClick(2)} className={activeTab === 2 ? "text-light-blue" : '' } >Values</h2>
                                            </div>
                                        </Motion> 
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='teb-content'>
                                            {
                                                activeTab === 0 &&
                                                <div>
                                                    <h4 className='fw-bold'>Empowering Futures, Enabling Innovation: Our Mission at terminaltree.co in the Digital Frontier.</h4>
                                                    <p className='mb-3 pt-4'>
                                                    We aspire to create and deliver the best digital solutions for businesses wanting to acquire a competitive edge in the industry and serve their customers through robust digital channels.
                                                    </p>
                                                    <p className='mb-0'>
                                                    Terminal Tree aims to help startups and organizations recognize their potential and leverage optimum, highly customized solutions for business growth and ROI.
                                                    </p>
                                                </div>
                                            }
                                            {
                                                activeTab === 1 &&
                                                <div>
                                                    <h4 className='fw-bold'>Cultivating Excellence: Our Core Values in Innovation, Integrity, and Teamwork.</h4>
                                                    <p className='mb-3 pt-4'>
                                                    At Terminal Tree, our vision is to be the foremost innovator in the software industry, driving transformative change and enabling businesses to thrive in the digital landscape. We aspire to create seamlessly interconnected solutions that anticipate and adapt to evolving technological trends, fostering a culture of creativity and collaboration within our team. Committed to excellence, we aim to be the trusted partner for organizations seeking cutting-edge digital solutions.
                                                    </p>
                                                    <p className='mb-0'>
                                                    Our success is intertwined with our clients' success, and we envision a future where our software not only meets but exceeds expectations, contributing to societal well-being and leaving a lasting legacy of positive impact.
                                                    </p>
                                                </div>
                                            }
                                            {
                                                activeTab === 2 &&
                                                <div>
                                                    <h4 className='fw-bold'>Futuristic Horizons: Our Vision for Innovation and Excellence in the IT Landscape.</h4>
                                                    <p className='mb-3 pt-4'>
                                                    As a forward-thinking software company, our core values revolve around innovation, excellence, and a relentless commitment to delivering cutting-edge solutions. We thrive on pushing technological boundaries, fostering a culture that encourages creativity and continuous learning. Customer satisfaction is at the heart of everything we do, and we prioritize transparency, integrity, and reliability in our interactions. Collaboration is ingrained in our DNA, both internally and externally, as we believe that diverse perspectives lead to superior outcomes.
                                                    </p>
                                                    <p className='mb-0'>
                                                    Our team is our greatest asset, and we invest in their growth and well-being to ensure a dynamic and empowered workforce. Embracing agility, adaptability, and a passion for solving complex challenges, we are dedicated to shaping the future through software that not only meets but exceeds the evolving needs of our clients and the ever-changing landscape of the digital world.
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    </Motion>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='tab-content vision-imgs'>
                                    {
                                        activeTab === 0 &&
                                        <img src={require("../../assets/images/mission-goals 1.svg").default} className="w-100" alt="" />
                                    }
                                    {
                                        activeTab === 1 &&
                                        <img src={require("../../assets/images/Vision.svg").default} className="w-100" alt="" />
                                    }
                                    {
                                        activeTab === 2 &&
                                        <img src={require("../../assets/images/value.svg").default} className="w-100" alt="" />
                                    }
                                </div>
                            </Motion>
                        </div>
                    </div>
                </div>
            </section>


        <section className="working-with">
            {/* <Motion classNames="transition-none" transition={{ duration: 0.3 }} variants={{ hidden: { opacity: 0, x: -70 }, visible: { opacity: 1, x: 0 } }}> */}
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-4 col-lg-4 mb-lg-0 mb-4">
                            <p className="mb-0 fw-bold text-light-grey text-start">Currently we’re working with...</p>
                        </div>
                        <div className="col-lg-7"> 
                            <OwlCarousel  
                                items= {3}   
                                margin={80} 
                                loop= {true} 
                                autoplay ={true} 
                                nav ={false}    
                                dots= {false} 
                                autoplayTimeout= {3000}
                                autoplayHoverPause= {false} 
                                autoplaySpeed={2000}
                                responsive={ {'1':{items:2}, '500':{items:2}, '600':{items:3},'1200':{items:3}}} id='working-slides'>
                                {items.map((item, index) => (
                                    <div className="item" key={index}>
                                    <div>
                                        <img src={item.imageSrc} alt={item.altText} />
                                    </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            {/* </Motion> */}
        </section>

        {/* <section className='team'>
            <div className='container-fluid'>
                <div className='row align-items-center'>
                    <div className='col-lg-12'>
                        <div className='col-lg-8 mb-5 pb-3'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <h2 className='text-light-grey'>Leadership Team</h2>
                            </Motion>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -70 }, visible: { opacity: 1, x: 0 } }}>
                                <p className='mb-0'>Meet the Visionaries: Our Leadership Team, a collective force of innovation and expertise, steering the course toward excellence with unwavering commitment and strategic insight.</p>
                            </Motion>
                        </div>
                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div className='team-holder-main'>
                                <div className='team-holder text-center'>
                                    <div className='team-holder-img'>
                                        <div className='team-role'><h4>Founder & CEO</h4></div>
                                        <div className='team-ovelay'></div>
                                    <img src='/images/team-img-2.svg' alt='img'/>
                                    </div>
                                    <h4 className='mb-0 mt-2 mt-md-4 pt-md-2'>Umair Zaid</h4> 
                                </div>
                                <div className='team-holder text-center'>
                                    <div className='team-holder-img'>
                                        <div className='team-role'><h4>Founder & CEO</h4></div>
                                        <div className='team-ovelay'></div>
                                    <img src={require('../../assets/images/Ellipse 229.svg').default}  alt='img'/>
                                    </div>
                                    <h4 className='mb-0 mt-2 mt-md-4 pt-md-2'>Ghazanfar Hussain</h4> 
                                </div>
                                <div className='team-holder text-center'>
                                    <div className='team-holder-img'>
                                        <div className='team-role'><h4>Founder & CEO</h4></div>
                                        <div className='team-ovelay'></div>
                                    <img src={require('../../assets/images/team-img-3.svg').default} alt='img'/>
                                    </div>
                                    <h4 className='mb-0 mt-2 mt-md-4 pt-md-2'>Philip Ellis</h4> 
                                </div>
                            </div>
                        </Motion>
                    </div>
                </div>
            </div>
        </section> */}

        <ContactSection />
    </>
  )
}

export default About
