import React from 'react'
import Motion from "../animation/Motion";

const ContactSection = () => {
  return (
    <>
        <section className="contact-us" id='contact-us'>
            <div className="container-fluid">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-5 contact-left-main">
                        <div className="contact-left">
                            <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <h3 className="mb-3">Let’s make it happen</h3>
                            </Motion>    
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className="location">
                                    <p><span className="me-3 pe-2"><img src={require("../../assets/images/loc-pin.svg").default} alt="" /></span>Lahore, Pakistan</p>
                                    <p><span className="me-3"><img src={require("../../assets/images/phone-icon.svg").default} alt="" /></span>info@terminaltree.com</p>
                                    {/* <p><span className="me-3"><img src={require("../../assets/images/mail-iocn.svg").default} alt="" /></span>+92 323 8484458</p> */}
                                </div>
                            </Motion>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="contact-right">
                            <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <h3 className="mb-4">Contact Us</h3>
                            </Motion>
                            <form action="">
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay:.2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <input type="text" className="form-control shadow-none " placeholder="Enter Your Full Name" />
                                </Motion>
                                <div className="row w-100 mx-auto">
                                    <div className="col-md-6 p-0">
                                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                            <input type="email" className="form-control shadow-none " placeholder="Enter Phone Email" />
                                        </Motion>
                                    </div>
                                    <div className="col-md-6 pe-0 ps-0 ps-md-2">
                                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                            <input type="text" className="form-control shadow-none " placeholder="Enter Phone Number" />
                                        </Motion>
                                    </div>
                                </div>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <textarea name="" id="" cols="30" rows="10" className="form-control shadow-none "  placeholder="Type Your Message..."></textarea>
                                </Motion>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .7 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <button type='submit' className=' start-btn contact-submit'>Submit Message 
                                        <span>
                                            <svg width="17" height="16" viewBox="0 0 17 16" fill="current" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M15.3333 8.00002H1.19114H15.3333Z" fill="#08213E"/>
                                                <path d="M15.3333 8.00002H1.19114" stroke="#08213E" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M15.3333 8.00002H1.19114" stroke="#08213E" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M8.96936 1.63599L15.3333 7.99995L8.96936 14.3639" stroke="#08213E" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M8.96936 1.63599L15.3333 7.99995L8.96936 14.3639" stroke="#08213E" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                    </button>
                                </Motion>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default ContactSection
