import React, { useState, useCallback } from 'react'
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Motion from "../animation/Motion"

const Projects = ({projectdata,visibleitem, filterCategory, modalshow, cardid}) => {

    function modalData(){
        modalshow(true)
    }
    
    const [selectedCard, setSelectedCard] = useState(null);

    const handleCardClick = useCallback((cardId) => {
        setSelectedCard((prevSelectedCard) => {
            if (prevSelectedCard !== cardId) {
                cardid(cardId);
            }
            return cardId;
        });
    }, [cardid]);

    useEffect(() => {
        cardid(selectedCard);
    }, [cardid, selectedCard]);

  return (
    <>
        {
            projectdata.slice(0,visibleitem).map((curElem) =>{
                return(
                    <div className='col-sm-6 col-lg-4' key={curElem.id}>
                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            {
                                curElem.category === 'LogoDesign' ?
                                <NavLink 
                                    // onClick={() =>CardClick()} 
                                    onClick={(event) => {
                                        modalData();
                                        handleCardClick(curElem.id);
                                        // sendId();
                                    }}
                                    className="mainProjectBox text-decoration-none">
                                    <div className={curElem.class}>
                                        <div className='project-boxes-image-main'>
                                            <img src={curElem.Image} alt="" />
                                        </div>
                                        <div className="project-inner-main">
                                            <small className="font-sofia">{curElem.categoryName}</small>
                                            <span  className="heading mb-0" >{curElem.heading}</span>
                                        </div>
                                    </div>
                                </NavLink>
                                :
                                <a href={`/casedetail/${curElem.casename}`} className="mainProjectBox text-decoration-none">
                                    <div className={curElem.class}>
                                        <div className='project-boxes-image-main'>
                                            <img src={curElem.Image} alt="" />
                                        </div>
                                        <div className="project-inner-main">
                                            <small className="font-sofia">{curElem.categoryName}</small>
                                            <span className="heading mb-0">{curElem.heading}</span>
                                        </div>
                                    </div>
                                </a>
                            }
                        </Motion>
                    </div>
                )
            })
        }
    </>
  )
}

export default Projects;
