import React from 'react'

const PrivacyPolicy = () => {
  return (
    <>
      <div className='privacy-main'>
        <div className='container-fluid'>
          <h1>Privacy Policy</h1>
          <div className='privacy-content-holder first'>
              <p><span className='fw-bold'>Terminal Tree</span> understands the importance of your personal privacy. Therefore, we have created this Privacy Policy so that you know how we use and disclose your information when you make it available to us. The Privacy Policy below discloses our practices regarding information collection and usage website located at datatron.com, the related mobile website, and the mobile application (collectively, the “Websites”) and the service provided there on (the “Service”).</p>
              <p>By using or accessing the Websites and the Service, you signify your agreement to be bound by this Privacy Policy. IF YOU DO NOT
                AGREE TO THIS PRIVACY POLICY YOU MAY NOT ACCESS OR OTHERWISE USE OUR SERVICE OR PARTICIPA­­TE IN OUR SERVICES.</p>
              <p>Key aspects of our privacy practices described in this Privacy Policy include the following explanations:</p>
              <ul>
                <li>24/7 services with no down time</li>
                <li>Reliable cataloging that keep your business assets safe</li>
                <li>Versatile support to connect models beyond servers and entities</li>
              </ul>
          </div>
          <div className='privacy-content-holder'>
              <h2>Personally Identifiable Information That We Collect:</h2>
              <p>We may ask you for, or you may voluntarily submit, personally identifiable information when you are using the Service. The personally identifiable information which you may provide to us could include, but is not limited to</p>
              <ul>
                <li>Your name</li>
                <li>Your contact information (including, without limitation, address and email address);</li>
                <li>Your IP address; and</li>
                <li>Other personal information.</li>
              </ul>
          </div>
          <div className='privacy-content-holder'>
            <h2>Non-Personal Or Aggregate Information That We Collect:</h2>
            <p>
                When you access our Service, we may automatically collect non-personally identifiable information from you, such as IP hostaddress, web pages viewed, browser type, operating system, referring service, search information, device type, page views, usage and browsing habits on the Service and similar data. We may also aggregate demographic information collected from our users (such as the number of users in a particular geographical location) in a manner which does not identify any one individual. We may also aggregate information collected offline in connection with the Service, obtain non-personally identifiable information from third
                party sources and develop aggregate information by anonymizing previously collected personally identifiable information.
            </p>
            <p>
                It is possible at times when collecting non-personally identifiable information through automatic means that we may unintentionally collect or receive personally identifiable information that is mixed in with the non-personally identifiable information. While we will make reasonable efforts to prevent such incidental data collection, the possibility still exists. If you believe that we have nadvertently collected your personal information, please notify us at
            </p>
          </div>
          <div className='privacy-content-holder'>
              <h2>Information Usage:</h2>
              <p>We will only use your personally identifiable information as described below, unless you have specifically consented to another type
                  of use, either at the time the personally identifiable information is collected from you or through some other form of consent from
                  you or notification to you:</p>
              <ul>
                <li>We may share your personally identifiable information collected in connection with providing the Service.</li>
                <li>We may use your personally identifiable information to respond to your inquires or requests.</li>
                <li>We may use your personally identifiable information to send you emails from time to time about our services, but we will not
                    provide your personally identifiable information to third parties for them to contact you directly unless otherwise permitted by
                    this Privacy Policy or you provide your consent.</li>
                <li>We may share your personally identifiable information with third parties (collectively, the “Third Party Vendors”) to further the
                    purpose for which you provided such information to us. For example, we may share your information with Elastic Email, for the
                    purpose of sending emails.</li>
              </ul>
          </div>
          <div className='privacy-content-holder'>
              <h2>We urge you to read the privacy practices of all of our Third Party Vendors before submitting any personally identifiable information through the Service.</h2>
              <ul>
                <li>We may share your personally identifiable information collected in connection with providing the Service.</li>
                <li>We may use your personally identifiable information to respond to your inquires or requests.</li>
                <li>We may use your personally identifiable information to send you emails from time to time about our services, but we will not
                    provide your personally identifiable information to third parties for them to contact you directly unless otherwise permitted by
                    this Privacy Policy or you provide your consent.</li>
                <li>We may share your personally identifiable information with third parties (collectively, the “Third Party Vendors”) to further the
                    purpose for which you provided such information to us. For example, we may share your information with Elastic Email, for the
                    purpose of sending emails.</li>
              </ul>
              <p>Non-personally identifiable or aggregate information may be used by us for any purposes permitted by law and may be shared with
                  any number of parties, provided that such information shall not specifically identify you.</p>
          </div>
          <div className='privacy-content-holder'>
            <h2>Cookies and Similar Technologies:</h2>
            <p>
              “Cookies” are pieces of information that may be placed on your computer by a service for the purpose of facilitating and enhancing your communication and interaction
              with that service. Many services use cookies for these purposes. We may use cookies (and similar items such as clear gifs, web beacons, tags, etc.) on our Service to 
              customize your visit and for other purposes to make your visit more convenient or to enable us to enhance our Service. We may also use and place cookies (and similar 
              items) on your computer from our third party service providers in connection with the Service, such as an analytics provider that helps us manage and analyze Service 
              usage, as described more fully below. In addition, our advertisers and business partners may set cookies and similar items on your computer when you use our Service.
              You may stop or restrict the placement of cookies on your computer or flush them from your browser by adjusting  your web browser preferences, in which case you may
              still use our Service, but it may nterfere with some of its functionality. Cookies and similar items are not used by us to automatically retrieve personally identifiable
              information from your computer without your knowledge.
            </p>
            <p>
            If you delete your cookies, change browsers or use a different cookie, our cookie (or an opt-out cookie) may no longer work and you
            will have to reinput (or opt-out) again.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy

