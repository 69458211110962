const ServiceApi = [
    {
        id : "1",
        serviceName: 'ui-ux-development',
        heading:'UI/UX Design Services',
        banner_para: 'Creating memorable and interactive UX and UI designs',
        banner_direction:'left',
        bannerbg_img:  require('../../assets/images/ui-service-bg.png'),
        // service Detail data
        serviceDetail_heading: 'Attractive, Responsive, Flexible UI/UX Design Services',
        serviceDetail_headingBlue: 'UI/UX design services focused on creating delightful digital product experiences.',
        serviceDetail_img: require('../../assets/images/ui-ux-image.svg').default,
        serviceDetail_para: "It’s not just about good-looking UI/UX design; it’s about a design that is easily perceived and navigated by users. It is a UI/UX design that will provide users with an enjoyable, smooth and frictionless surfing experience.",
        // Service Detail card data
        detailCard_heading: 'Attract Wider Audiences',
        detailCard_para: 'Mobile device usage is now the norm. A beautifully designed responsive website or app ensures that whatever mobile device your customers use to reach your business, it will have a smooth and exquisite experiences.',
        detailCard_heading2: 'Results Driven Approach',
        detailCard_para2: 'Designs interactive apps and websites that your customers (and theirs) will most definitely fall in love with. We design products that reflect business goals and end-user needs, driven by research and hard data.',
        detailCard_heading3: 'Stay Ahead of the Curve',
        detailCard_para3: 'We keep up with technology, design and commerce trends and ensure unique and dynamic web experiences. At Terminal, we cover the full spectrum of design, from mobile app UI/UX design to user research and testing.',
        // procces section data
        procces_img: require('../../assets/images/mob-img.png'),
        process_heading: 'UI/UX Design Process',
        process_headingBlue: 'Together we’ll design great things.',
        process_card1_heading: 'Discovery',
        process_card2_heading: 'Research',
        process_card3_heading: 'Planning',
        process_card4_heading: 'Creation',
        process_card5_heading: 'Testing',
        process_card6_heading: 'Delivery',
        // procces card lists
        process_card1_list1: 'Define Goals',
        process_card1_list2: 'Target Audience',
        process_card1_list3: 'Market Conditions',
        process_card1_list4: 'Contraints',
        
        process_card2_list1: 'User Research',
        process_card2_list2: 'Personas',
        process_card2_list3: 'User Behaviors',
        process_card2_list4: 'Competitor Analysis',

        process_card3_list1: 'Goals and Deliverables',
        process_card3_list2: 'Resource Allocation',
        process_card3_list3: 'Project Planning',
        process_card3_list4: 'Documentation',

        process_card4_list1: 'Sketches',
        process_card4_list2: 'Wireframes',
        process_card4_list3: 'Nav Concepts & Signs',
        process_card4_list4: 'Illustrations',

        process_card5_list1: 'Mock-ups',
        process_card5_list2: 'Usability Testing',
        process_card5_list3: 'Heuristic evaluation',
        process_card5_list4: 'User Feedback',

        process_card6_list1: 'Design System',
        process_card6_list2: 'Guidance',
        process_card6_list3: 'Rollout Strategy',
        process_card6_list4: 'Training',
        // sections hide and show
        service_spec:true,
        dev_count:false,
        tech_weUse:false,
        dev_portfolio:false,
        awardWining_section: true,
        process_setion: true,
        ideaReality_section: false,
        shipment_section: false,
        flexible: true,
        flexible_bg: require('../../assets/images/Rectangle24790.png'),
        strategy: false,
        contact_bg_color: 'white',
        // award section data
        award_bg: require('../../assets/images/award-bg-ui.png'),
        award_heading: 'Award winning user interfaces inspired by innovation',
        award_para: 'We approach every UX/UI design project, big or small, as a defining moment. What we do is showcased by our triumphs.',
    },
    {
        id : "2",
        serviceName: 'android-app-development',
        heading:'Android App Development',
        banner_para: 'Creating memorable and interactive UX and UI designs',
        banner_direction:'left',
        bannerbg_img:  require('../../assets/images/android-bg.png'),
        // service Detail data
        serviceDetail_heading: 'From Concept to Reality Prioritizing Mobile User Experience in Software Development',
        serviceDetail_img: require('../../assets/images/andriod-detailimg.png'),
        serviceDetail_para: "We've been making phone apps right from the beginning, creating websites that work well on phones and special apps for phones too. It's super important for businesses to focus on phones first nowadays. If you're thinking of making any kind of computer program, making sure it works great on phones is the very first thing to think about. We've spent more than 3+ years making really good phone apps. We use smart methods to send out apps for iPhones, Androids, and other types of phones every week. Want to talk about your app plans? Tell our team your goals, and we'll plan together!",
        // Service Detail card data
        detailCard_heading: 'Android Apps',
        detailCard_para: 'Mobile device usage is now the norm. A beautifully designed responsive website or app ensures that whatever mobile device your customers use to reach your business, it will have a smooth and exquisite experiences.',
        detailCard_heading2: 'Progressive Web Apps',
        detailCard_para2: 'Designs interactive apps and websites that your customers (and theirs) will most definitely fall in love with. We design products that reflect business goals and end-user needs, driven by research and hard data.',
        detailCard_heading3: 'Hybrid Apps',
        detailCard_para3: 'We keep up with technology, design and commerce trends and ensure unique and dynamic web experiences. At Terminal, we cover the full spectrum of design, from mobile app UI/UX design to user research and testing.',
        // procces section data
        procces_img: require('../../assets/images/mob-img.png'),
        process_heading: 'The Terminal Mobile App Development Process',
        process_headingBlue: 'Together we’ll build great apps.',
        process_card1_heading: 'Discovery',
        process_card2_heading: 'Research',
        process_card3_heading: 'Planning',
        process_card4_heading: 'Creation',
        process_card5_heading: 'Testing',
        process_card6_heading: 'Delivery',
        // procces card lists
        process_card1_list1: 'Define Goals',
        process_card1_list2: 'Target Audience',
        process_card1_list3: 'Market Conditions',
        process_card1_list4: 'Contraints',
        
        process_card2_list1: 'User Research',
        process_card2_list2: 'Personas',
        process_card2_list3: 'User Behaviors',
        process_card2_list4: 'Competitor Analysis',

        process_card3_list1: 'Goals and Deliverables',
        process_card3_list2: 'Resource Allocation',
        process_card3_list3: 'Project Planning',
        process_card3_list4: 'Documentation',

        process_card4_list1: 'Sketches',
        process_card4_list2: 'Wireframes',
        process_card4_list3: 'Nav Concepts & Signs',
        process_card4_list4: 'Illustrations',

        process_card5_list1: 'Mock-ups',
        process_card5_list2: 'Usability Testing',
        process_card5_list3: 'Heuristic evaluation',
        process_card5_list4: 'User Feedback',

        process_card6_list1: 'Design System',
        process_card6_list2: 'Guidance',
        process_card6_list3: 'Rollout Strategy',
        process_card6_list4: 'Training',
        // sections hide and show
        service_spec:true,
        dev_count:false,
        tech_weUse:false,
        dev_portfolio:false,
        awardWining_section: false,
        process_setion: true,
        ideaReality_section: true,
        ideaReality_bg: require('../../assets/images/Rectangle24789.png'),
        shipment_section: false,
        flexible: true,
        flexible_bg: require('../../assets/images/flexible-bg-2.png'),
        strategy: true,
        contact_bg_color: 'white',

        mobile_app_img: require('../../assets/images/pngwing 3.svg').default,
    },

    {
        id : "3",
        serviceName: 'ios-app-development',
        heading:'IOS App Development',
        banner_para: 'Creating memorable and interactive UX and UI designs',
        banner_direction:'left',
        bannerbg_img:  require('../../assets/images/ios-bg.png'),
        // service Detail data
        serviceDetail_heading: 'Making Apps Awesome for Over 3+ Years!',
        serviceDetail_img: require('../../assets/images/ios-detailimg.png'),
        serviceDetail_para: "Being pioneers in iOS mobile app development for over 3+ years, we specialize in creating top-notch responsive web and native mobile apps. Recognizing the importance of a mobile-first strategy for businesses, we emphasize the need for careful planning and focus on delivering an excellent mobile user experience. With a wealth of experience in designing, building, and supporting world-class mobile applications, our Agile engineering principles enable us to consistently release high-quality iOS, Android, and platform-agnostic apps every week. For a personalized discussion on shaping your app strategy, share your goals and objectives with our dedicated mobile development team today.",
        // Service Detail card data
        detailCard_heading: 'iOS Apps',
        detailCard_para: 'Mobile device usage is now the norm. A beautifully designed responsive website or app ensures that whatever mobile device your customers use to reach your business, it will have a smooth and exquisite experiences.',
        detailCard_heading2: 'Progressive Web Apps',
        detailCard_para2: 'Designs interactive apps and websites that your customers (and theirs) will most definitely fall in love with. We design products that reflect business goals and end-user needs, driven by research and hard data.',
        detailCard_heading3: 'Hybrid Apps',
        detailCard_para3: 'We keep up with technology, design and commerce trends and ensure unique and dynamic web experiences. At Terminal, we cover the full spectrum of design, from mobile app UI/UX design to user research and testing.',
        // procces section data
        procces_img: require('../../assets/images/mob-img2.png'),
        process_heading: 'The Terminal Mobile App Development Process',
        process_headingBlue: 'Together we’ll build great apps.',
        process_card1_heading: 'Discovery',
        process_card2_heading: 'Research',
        process_card3_heading: 'Planning',
        process_card4_heading: 'Creation',
        process_card5_heading: 'Testing',
        process_card6_heading: 'Delivery',
        // procces card lists
        process_card1_list1: 'Define Goals',
        process_card1_list2: 'Target Audience',
        process_card1_list3: 'Market Conditions',
        process_card1_list4: 'Contraints',
        
        process_card2_list1: 'User Research',
        process_card2_list2: 'Personas',
        process_card2_list3: 'User Behaviors',
        process_card2_list4: 'Competitor Analysis',

        process_card3_list1: 'Goals and Deliverables',
        process_card3_list2: 'Resource Allocation',
        process_card3_list3: 'Project Planning',
        process_card3_list4: 'Documentation',

        process_card4_list1: 'Sketches',
        process_card4_list2: 'Wireframes',
        process_card4_list3: 'Nav Concepts & Signs',
        process_card4_list4: 'Illustrations',

        process_card5_list1: 'Mock-ups',
        process_card5_list2: 'Usability Testing',
        process_card5_list3: 'Heuristic evaluation',
        process_card5_list4: 'User Feedback',

        process_card6_list1: 'Design System',
        process_card6_list2: 'Guidance',
        process_card6_list3: 'Rollout Strategy',
        process_card6_list4: 'Training',
        // sections hide and show
        service_spec:true,
        dev_count:false,
        tech_weUse:false,
        dev_portfolio:false,
        awardWining_section: false,
        process_setion: true,
        ideaReality_section: true,
        ideaReality_bg: require('../../assets/images/ios-idea-bg.svg').default,
        shipment_section: false,
        flexible: true,
        flexible_bg: require('../../assets/images/ios-flexible-bg.png'),
        strategy: true,
        contact_bg_color: 'white',

        mobile_app_img: require('../../assets/images/mob-blue-screen.png'),
    },

    {
        id : "4",
        serviceName: 'custom-software-development',
        heading:'Custom Software Development',
        banner_para: 'Americas best software engineers. Affordable and on-demand, 24/7.',
        banner_direction:'left',
        bannerbg_img:  require('../../assets/images/customSoftware-bg.png'),
        // service Detail data
        serviceDetail_heading: 'Make Terminal your systems integration partner',
        serviceDetail_img: require('../../assets/images/Untitled-5 3.png'),
        serviceDetail_para: "Terminal Tree is a full-stack software development company with over 3+ years of experience and expertise in delivering unparalleled digital solutions. We are an agile software development company employing key modern software concepts enabling us to build a custom, enterprise-grade solutions to meet every need.",
        serviceDetail_headingBlue: 'Together, we’ll build great things.',
        // Service Detail card data
        detailCard_heading: 'Advisory',
        detailCard_para: 'We are MVP’s, and we know technology inside and out. We have unlimited and unrestricted access to new and future technologies which gives us an amazing wealth of information and competitive edge when recommending the best solutions to our customers.',
        detailCard_heading2: 'Expertise',
        detailCard_para2: 'Terminal brings recognized software engineering thought-leadership. We help you identify the right technologies for your needs today and into tomorrow. We help you make the right investments at the right time, and avoid common (and costly) mistakes.',
        detailCard_heading3: 'Agile',
        detailCard_para3: 'When you need assistance you will talk directly to a developer, a doer and not an account or project manager. We are agile and nimble. Some turn a simple conference call into an all-hands meeting where everyone is billable, not Maxiom. You’ll like our no sticker shock policy.',
        detailCard_heading4: 'Quality',
        detailCard_para4: 'Our custom software engineering services are designed to provide you with the highest quality with less of your time and effort. Our experienced teams of elite software professionals deliver high-quality products tailored to how you do business.',
        detailCard_heading5: 'Creativity',
        detailCard_para5: 'Our engagements begin with in-depth analysis of your business. We learn about your products and services, and your business philosophy. The creative process then begins by shaping the final solution with attractive and flexible UX and UI.',
        detailCard_heading6: 'Innovation',
        detailCard_para6: 'The software we develop incorporates every unique feature necessary to address your specific business needs. Our experience in custom software engineering and design makes our developed software applications innovative and cost effective.',
        moreDetail_card: true,
        // sections hide and show
        service_spec:true,
        dev_count:true,
        tech_weUse:false,
        dev_portfolio:true,
        awardWining_section: false,
        process_setion: false,
        ideaReality_section: false,
        our_deed:true,
        custom_solutions: true,
        emerging_tech:true,
        // award section data
        award_bg: require('../../assets/images/award-bg-ui.png'),
        award_heading: 'Award winning software development company inspired by innovation',
        award_para: 'We approach every project, big or small, as a defining moment. Here we share our triumphs..',
        shipment_section: false,
        flexible: false,
        strategy: false,
        contact_bg_color: 'white',

        softwareShip_img: require('../../assets/images/image 147.png'),
    },
    {
        id : "5",
        serviceName: 'website-development',
        heading:'Website Development',
        banner_para: 'Americas best software engineers. Affordable and on-demand, 24/7.',
        banner_direction:'left',
        bannerbg_img:  require('../../assets/images/website-bg.png'),
        // service Detail data
        serviceDetail_heading: 'Make Terminal your systems integration partner',
        serviceDetail_img: require('../../assets/images/Untitled-5 4.png'),
        serviceDetail_para: "Terminal Tree is a full-stack software development company with over 3+ years of experience and expertise in delivering unparalleled digital solutions. We are an agile software development company employing key modern software concepts enabling us to build a custom, enterprise-grade solutions to meet every need.",
        serviceDetail_headingBlue: 'Together, we’ll build great things.',
        // Service Detail card data
        detailCard_heading: 'Advisory',
        detailCard_para: 'We are MVP’s, and we know technology inside and out. We have unlimited and unrestricted access to new and future technologies which gives us an amazing wealth of information and competitive edge when recommending the best solutions to our customers.',
        detailCard_heading2: 'Expertise',
        detailCard_para2: 'Terminal brings recognized software engineering thought-leadership. We help you identify the right technologies for your needs today and into tomorrow. We help you make the right investments at the right time, and avoid common (and costly) mistakes.',
        detailCard_heading3: 'Agile',
        detailCard_para3: 'When you need assistance you will talk directly to a developer, a doer and not an account or project manager. We are agile and nimble. Some turn a simple conference call into an all-hands meeting where everyone is billable, not Maxiom. You’ll like our no sticker shock policy.',
        detailCard_heading4: 'Quality',
        detailCard_para4: 'Our custom software engineering services are designed to provide you with the highest quality with less of your time and effort. Our experienced teams of elite software professionals deliver high-quality products tailored to how you do business.',
        detailCard_heading5: 'Creativity',
        detailCard_para5: 'Our engagements begin with in-depth analysis of your business. We learn about your products and services, and your business philosophy. The creative process then begins by shaping the final solution with attractive and flexible UX and UI.',
        detailCard_heading6: 'Innovation',
        detailCard_para6: 'The software we develop incorporates every unique feature necessary to address your specific business needs. Our experience in custom software engineering and design makes our developed software applications innovative and cost effective.',
        moreDetail_card: true,
        // sections hide and show
        service_spec:true,
        dev_count:false,
        tech_weUse:true,
        tech_weUse_bg: require('../../assets/images/tech-bg.png'),
        dev_portfolio:false,
        awardWining_section: false,
        process_setion: false,
        ideaReality_section: false,
        our_deed:true,
        custom_solutions: true,
        // award section data
        award_bg: require('../../assets/images/award-bg-ui.png'),
        award_heading: 'Award winning software development company inspired by innovation',
        award_para: 'We approach every project, big or small, as a defining moment. Here we share our triumphs..',
        shipment_section: false,
        flexible: false,
        strategy: false,
        web_working_process: true,
        contact_bg_color: 'white',

        softwareShip_img: require('../../assets/images/Untitled-5 5.png'),
    },
    {
        id : "6",
        serviceName: 'system-integration',
        heading:'System Integration',
        banner_para: 'Simplifying and orchestrating complex information system footprints.',
        banner_direction:'left',
        bannerbg_img:  require('../../assets/images/systemIntegration-bg.png'),
        // service Detail data
        serviceDetail_heading: 'Make Terminal your systems integration partner',
        serviceDetail_img: require('../../assets/images/system-automation 1.png'),
        serviceDetail_para: "Terminal Tree is a company that puts together computer systems, and we've been doing it for more than 3+ years.We're really good at making software, which allows us to create custom solutions for big businesses.We can make exactly what you need, using the latest and best ideas in software",
        serviceDetail_headingBlue: 'Together, we’ll build great things.',
        // Service Detail card data
        detailCard_heading: 'Advisory',
        detailCard_para: 'We are MVP’s, and we know technology inside and out. We have unlimited and unrestricted access to new and future technologies which gives us an amazing wealth of information and competitive edge when recommending the best solutions to our customers.',
        detailCard_heading2: 'Expertise',
        detailCard_para2: 'Terminal brings recognized software engineering thought-leadership. We help you identify the right technologies for your needs today and into tomorrow. We help you make the right investments at the right time, and avoid common (and costly) mistakes.',
        detailCard_heading3: 'Agile',
        detailCard_para3: 'When you need assistance you will talk directly to a developer, a doer and not an account or project manager. We are agile and nimble. Some turn a simple conference call into an all-hands meeting where everyone is billable, not Maxiom. You’ll like our no sticker shock policy.',
        detailCard_heading4: 'Quality',
        detailCard_para4: 'Our custom software engineering services are designed to provide you with the highest quality with less of your time and effort. Our experienced teams of elite software professionals deliver high-quality products tailored to how you do business.',
        detailCard_heading5: 'Creativity',
        detailCard_para5: 'Our engagements begin with in-depth analysis of your business. We learn about your products and services, and your business philosophy. The creative process then begins by shaping the final solution with attractive and flexible UX and UI.',
        detailCard_heading6: 'Innovation',
        detailCard_para6: 'The software we develop incorporates every unique feature necessary to address your specific business needs. Our experience in custom software engineering and design makes our developed software applications innovative and cost effective.',
        moreDetail_card: true,
        // sections hide and show
        service_spec:true,
        dev_count:false,
        tech_weUse:true,
        tech_weUse_bg: require('../../assets/images/tech-bg-2.png'),
        tech_weUse_white:true,
        dev_portfolio:false,
        awardWining_section: false,
        process_setion: false,
        ideaReality_section: false,
        shipment_section: true,
        flexible: false,
        strategy: false,
        contact_bg_color: 'white',
        // award section data
        award_bg: require('../../assets/images/award-bg-ui.png'),
        award_heading: 'Award winning software development company inspired by innovation',
        award_para: 'We approach every project, big or small, as a defining moment. Here we share our triumphs..',

        softwareShip_img: require('../../assets/images/image 147.png'),
    },
    {
        id : "7",
        serviceName: 'cloud-native-development',
        heading:'Cloud Native Development',
        banner_para: 'Americas best software engineers. Affordable and on-demand, 24/7.',
        banner_direction:'center',
        bannerbg_img:  require('../../assets/images/cloud-bg.png'),
        // service Detail data
        serviceDetail_heading: 'Attractive, Responsive, Flexible UI/UX Design Services',
        serviceDetail_img: require('../../assets/images/z-logo.svg').default,
        serviceDetail_para: "It’s not just about good-looking UI/UX design; it’s about a design that is easily perceived and navigated by users. It is a UI/UX design that will provide users with an enjoyable, smooth and frictionless surfing experience.",
        // Service Detail card data
        detailCard_heading: 'Attract Wider Audiences',
        detailCard_para: 'Mobile device usage is now the norm. A beautifully designed responsive website or app ensures that whatever mobile device your customers use to reach your business, it will have a smooth and exquisite experiences.',
        detailCard_heading2: 'Results Driven Approach',
        detailCard_para2: 'Mobile device usage is now the norm. A beautifully designed responsive website or app ensures that whatever mobile device your customers use to reach your business, it will have a smooth and exquisite experiences.',
        detailCard_heading3: 'Stay Ahead of the Curve',
        detailCard_para3: 'Mobile device usage is now the norm. A beautifully designed responsive website or app ensures that whatever mobile device your customers use to reach your business, it will have a smooth and exquisite experiences.',
        // sections hide and show
        service_spec:false,
        dev_count:false,
        tech_weUse:false,
        dev_portfolio:false,
        awardWining_section: false,
        process_setion: false,
        ideaReality_section: false,
        shipment_section: false,
        flexible: false,
        strategy: false,
        cloud_page: true,
        contact_bg_color: 'white',
        // award section data
        award_bg: require('../../assets/images/award-bg-ui.png'),
        award_heading: 'Award winning user interfaces inspired by innovation',
        award_para: 'We approach every UX/UI design project, big or small, as a defining moment. What we do is showcased by our triumphs.',
    },
    {
        id : "8",
        serviceName: 'e-commerce-development',
        heading:'E-Commerce Development',
        banner_para: '',
        banner_direction:'left',
        bannerbg_img:  require('../../assets/images/ecomerence-bg.png'),
        // service Detail data
        serviceDetail_heading: 'E-commerce platform',
        serviceDetail_img: require('../../assets/images/NicePng_ecommerce.png'),
        serviceDetail_para: "E-commerce Development Terminal Tree is a top-tier E-Commerce Devolpment Company, transforming businesses globally by providing cutting-edge, user-friendly e-comerce solutions, enhancing customer experience, and driving unprecedented growth",
        // Service Detail card data
        detailCard_heading: 'B2C (Business-to-Consumer):',
        detailCard_heading2: 'B2B (Business-to-Business):',
        detailCard_heading3: 'C2C (Consumer-to-Consumer):',
        cardList_format: true,
        detailCard1_list1: 'B2C e-commerce involves businesses selling products or services directly to individual consumers.',
        detailCard1_list2: 'Examples include online retail stores, digital content marketplaces, and subscription box services.',
        detailCard1_list3: 'User experience, design, and personalized shopping are often key focuses in B2C development.',
        detailCard2_list1: 'B2C e-commerce involves businesses selling products or services directly to individual consumers.',
        detailCard2_list2: 'Examples include online retail stores, digital content marketplaces, and subscription box services.',
        detailCard2_list3: 'User experience, design, and personalized shopping are often key focuses in B2C development.',
        detailCard3_list1: 'C2C e-commerce facilitates transactions between individual consumers.',
        detailCard3_list2: 'Examples include online auction platforms like eBay and classified ad sites like Craigslist.',
        detailCard3_list3: 'Reputation systems and secure payment methods are critical in C2C platforms.',
        // procces section data
        procces_img: require('../../assets/images/e-com-procces-img.svg').default,
        process_heading: 'The Terminal E-comerce Platforms Development Process',
        process_headingBlue: 'Together we’ll design great things.',
        process_card1_heading: 'Discovery',
        process_card2_heading: 'Research',
        process_card3_heading: 'Architecture and Design:',
        process_card4_heading: 'Development:',
        process_card5_heading: 'Security:',
        process_card6_heading: 'Deployment and Scaling:',
        // procces card lists
        process_card1_list1: 'Define Goals',
        process_card1_list2: 'Target Audience',
        process_card1_list3: 'Market Conditions',
        process_card1_list4: 'Contraints',
        
        process_card2_list1: 'User Research',
        process_card2_list2: 'Personas',
        process_card2_list3: 'User Behaviors',
        process_card2_list4: 'Competitor Analysis',

        process_card3_list1: 'Work with your client to design the architecture of the cloud-native application. This often involves choosing appropriate cloud services and technologies.',

        process_card4_list1: 'Implement the application using a microservices architecture if appropriate. Each microservice should have its own repository and CI/CD pipeline.',

        process_card5_list1: 'Prioritize security throughout the development process. Implement best practices for securing containers, microservices, and cloud resources.',

        process_card6_list1: 'Configure auto-scaling rules to ensure resources automatically adjust to changes in demand.',
        
        // sections hide and show
        service_spec:true,
        dev_count:false,
        tech_weUse:false,
        dev_portfolio:true,
        awardWining_section: false,
        process_setion: true,
        ideaReality_section: false,
        shipment_section: false,
        flexible: false,
        strategy: false,
        ecommerce_success: true,
        contact_bg_color: 'white',

        mobile_app_img: require('../../assets/images/cup-mobile.png'),
    },
    {
        id : "9",
        serviceName: 'blockchain-development',
        heading:'Blockchain Development',
        banner_para: '',
        banner_direction:'left',
        bannerbg_img:  require('../../assets/images/blockchain-bg.png'),
        // service Detail data
        serviceDetail_heading: 'Blockchain Development',
        serviceDetail_img: require('../../assets/images/image 116.png'),
        serviceDetail_para: "Blockchain development encompasses various types or use cases, each serving different purposes and industries. Here are some common types of blockchain development:",
        // Service Detail card data
        detailCard_heading: 'Cryptocurrencies and Digital Assets:',
        detailCard_heading2: 'Smart Contracts',
        detailCard_heading3: 'Supply Chain Management:',
        cardList_format: true,
        detailCard1_list1: 'Bitcoin and Ethereum are the most well-known examples of blockchain platforms used for creating and trading cryptocurrencies.',
        detailCard1_list2: 'Developers in this space focus on creating and maintaining blockchain networks, wallets, and exchanges for digital currencies.',
        detailCard1_list3: "",
        detailCard2_list1: 'Smart contracts are self-executing contracts with the terms directly written into code.',
        detailCard2_list2: 'Ethereum is a prominent platform for building decentralized applications (DApps) that use smart contracts for automated and trustless transactions.',
        detailCard2_list3: "",

        detailCard3_list1: 'Blockchain technology is used to enhance transparency and traceability in supply chains.',
        detailCard3_list2: 'Developers build blockchain solutions to track the movement of goods, verify authenticity, and reduce fraud.',
        detailCard3_list3:'',
        // procces section data
        procces_img: require('../../assets/images/NicePng_ecommerce-png_2474974 2.svg').default,
        process_heading: 'The Terminal Blockchain Development Process',
        process_headingBlue: 'Together we’ll design great things.',
        process_card1_heading: 'Discovery',
        process_card2_heading: 'Define the Use Case:',
        process_card3_heading: 'Planning',
        process_card4_heading: 'Develop Smart Contracts:',
        process_card5_heading: 'Security Audits:',
        process_card6_heading: 'Deployment:',
        // procces card lists
        process_card1_list1: 'Define Goals',
        process_card1_list2: 'Target Audience',
        process_card1_list3: 'Market Conditions',
        process_card1_list4: 'Contraints',
        
        process_card2_list1: 'Clearly understand the problem you want to solve or the specific application for which you need a blockchain.',

        process_card3_list1: 'Create a high-level architecture design that outlines the components of your blockchain system, including nodes, consensus mechanisms, data structures, and interfaces.',

        process_card4_list1: 'Write and test smart contracts that will run on the blockchain. Ensure security and efficiency in coding.',

        process_card5_list1: 'Conduct security audits and code reviews to identify vulnerabilities and ensure the systems robustness.',

        process_card6_list1: 'Deploy the blockchain network and smart contracts to the target environment (mainnet, testnet, or private network',
        // sections hide and show
        service_spec:true,
        dev_count:false,
        tech_weUse:false,
        dev_portfolio:false,
        awardWining_section: false,
        process_setion: true,
        ideaReality_section: false,
        shipment_section: false,
        flexible: false,
        strategy: false,
        blockchain_product: true,
        zodiac_blockchain:true,
        contact_bg_color: 'white',
        blockchain_dev_opt:true,

        mobile_app_img: require('../../assets/images/cup-mobile.png'),
    }

]

export default ServiceApi