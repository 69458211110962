import React from 'react'
import ServiceApi from "../api/serviceApi";
import { Link } from 'react-router-dom';

function ServicesDropdown(props) {
  return (
    <>
        {
            ServiceApi.map((curElem)=>{
                if(props.dropPos === "header"){
                    return(
                        <div key={curElem.id}>
                            <li><Link className="dropdown-item" to={`/services/${curElem.serviceName}`}>{curElem.heading}</Link></li>
                        </div>
                    )
                }
                else if(props.dropPos === "footer"){
                    return(
                        <div key={curElem.id}>
                            <li><a className="dropdown-item" href={`/services/${curElem.serviceName}`}>{curElem.heading}</a></li>
                        </div>
                    )
                }
                else{
                    return null
                }
            })
        }
    </>
  )
}

export default ServicesDropdown
