import React, { useState, useEffect } from 'react';

const CounterAnimation = ({ limit, step }) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const counter_step = step
    const interval = setInterval(() => {
      if (counter < limit) {
        setCounter(prevCounter => prevCounter + counter_step);
      }
    }, 30);

    return () => clearInterval(interval);
  }, [counter, limit,step]);

  return (
    <div>
      <h2>{counter}</h2>
    </div>
  );
};

export default CounterAnimation;