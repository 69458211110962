import React from 'react'
import CountryDropdown from '../animation/CountryDropdown';
import Motion from '../animation/Motion';
const ContactUs = () => {

  return (
    <>
        <section className="detail-banner">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-9">
                            <div className="position-relative">
                            <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <h2 className="mb-3">Contact Us</h2>
                            </Motion>
                            <Motion classNames="transition-none" transition={{ duration: 0.4 , delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <p className="f-18px">Tell us about your product concept and we’ll get back to you with details on how we can make this work. Or just ask us anything product related</p>
                            </Motion>
                        </div>
                        <Motion classNames="transition-none" transition={{ duration: 0.4 , delay: .3 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div className="map-section">
                                <div className="position-relative">
                                    <img src={require("../../assets/images/map-img.png")} className="w-100" alt="" />
                                    <div className="map-pin text-center">
                                        <div className="address-box">
                                            <small>Pakistan, Lahore</small>
                                            <small className="small">Johar Town N, Block</small>    
                                        </div>
                                        <div>
                                            <img src={require("../../assets/images/Pin.svg").default} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Motion>
                    </div>
                    <div className="col-lg-3">
                        <div className="px-3 mt-5 pt-5 contact-list">
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .3 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className="text-center mb-5">
                                    <div><img src={require("../../assets/images/email-icon.svg" ).default}alt="" /></div>
                                    <h4 className="text-grey mt-4 mb-2 pb-1 ">Email us</h4>
                                    <p className="mb-2 font-sofia">Email us for general queries, including marketing and partnership opportunities.</p>
                                    <a  href="mailto:info@terminaltree.com" className="text-light-blue font-sofia text-decoration-none d-block">info@terminaltree.com</a>
                                </div>
                            </Motion>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className="text-center mb-5">
                                    <div><img src={require("../../assets/images/phone-icon-blue.svg").default} alt="" /></div>
                                    <h4 className="text-grey mt-4 mb-2 pb-1 ">Call us</h4>
                                    <p className="mb-2 font-sofia">Call us to speak to a member of our team. We are always happy to help.</p>
                                    {/* <a href="tel:+92 (323) 8484458" className="text-light-blue font-sofia text-decoration-none d-block">+92 (323) 8484458</a> */}
                                </div>
                            </Motion>
                            {/* <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className="text-center mb-5">
                                    <div><img src={require("../../assets/images/women-icon-blue.svg").default} alt="" /></div>
                                    <h4 className="text-grey mt-4 mb-2 pb-1 ">Support</h4>
                                    <p className="mb-3 pb-1 font-sofia">Check out helpful resources, FAQs and developer tools.</p>
                                    <a href="/" className="support-btn start-btn border-2 text-light-blue py-1 fw-medium font-sofia text-decoration-none d-block">Support Center</a>
                                </div>
                            </Motion> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="contect-us">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-xl-4 mb-4 mb-xl-0">
                        <div className="contact-us-form bg-white">
                            <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <h3 className="mb-3 pb-1">Let’s level up your brand</h3>
                            </Motion>
                            <Motion classNames="transition-none" transition={{ duration: 0.4 , delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <p className="font-sofia mb-5">You can reach us anytime <span className="text-light-blue">info@terminaltree.com</span></p>
                            </Motion>
                            <form action="">
                                <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className="row w-100 mx-auto">
                                        <div className="col-md-6 p-0">
                                            <label htmlFor="">First Name</label>
                                            <input type="text" className="form-control shadow-none mb-4" placeholder="First name" />
                                        </div>
                                        <div className="col-md-6 pe-0 ps-0 ps-md-3">
                                            <label htmlFor="">Last Name</label>
                                            <input type="text" className="form-control shadow-none mb-4" placeholder="Last name" />
                                        </div>
                                    </div>
                                </Motion>
                                <Motion classNames="transition-none" transition={{ duration: 0.4 , delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className="form-group">
                                        <label htmlFor="">Email</label>
                                        <input type="email" className="form-control shadow-none mb-4" placeholder="you@company.com" />
                                    </div>
                                </Motion>
                                <Motion classNames="transition-none" transition={{ duration: 0.4 , delay: .3 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className="form-group">
                                        <label htmlFor="">Phone Number</label>
                                        <div className='country-box'>
                                            <CountryDropdown />
                                            <input type="text" className="form-control shadow-none mb-4" placeholder="+1 (555) 000-0000" />
                                        </div>
                                    </div>
                                </Motion>
                                <Motion classNames="transition-none" transition={{ duration: 0.4 , delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className="form-group">
                                        <label htmlFor="">Message</label>
                                        <textarea name="" id="" cols="30" rows="5" className="form-control shadow-none mb-4"  placeholder="Type Your Message..."></textarea>
                                    </div>
                                </Motion>
                                <Motion classNames="transition-none" transition={{ duration: 0.4 , delay: .5 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <button type="submit" className="contact-submit start-btn w-100 py-sm-4 py-3">
                                        Submit Message
                                        <span className='ms-4'>
                                            <svg width="17" height="16" viewBox="0 0 17 16" fill="current" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M15.3333 8.00002H1.19114H15.3333Z" fill="#08213E"/>
                                                <path d="M15.3333 8.00002H1.19114" stroke="#08213E" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M15.3333 8.00002H1.19114" stroke="#08213E" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M8.96936 1.63599L15.3333 7.99995L8.96936 14.3639" stroke="#08213E" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M8.96936 1.63599L15.3333 7.99995L8.96936 14.3639" stroke="#08213E" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                    </button>
                                </Motion>
                            </form>
                        </div>
                    </div>
                    <div className="col-xl-8">
                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .3 }} variants={{ hidden: { opacity: 0, x: -40 }, visible: { opacity: 1, x: 0 } }}>
                            <div className='contact-us-right'><img src={require("../../assets/images/Image-contact.png")} className='w-100' alt="" /></div>
                        </Motion>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default ContactUs
