
const ProjectApi = [
    {
        id : 1,
        casename: 'shft-in',
        heading : "T-shirt design is the part of design",
        category:"UI/UX",
        Image : require('../../assets/images/shftin-ui-projectimg.png'),
        categoryName:"UI/UX",
    },
    {
        id : 2,
        casename: 'easy-crypto',
        heading : "The service provide for designer",
        category:"WebDevelopment",
        Image : require('../../assets/images/image 98.png'),
        categoryName:"Web Design",
        class : 'green  project-boxes',
    },
    {
        id : 3,
        casename: 'infinity8.io',
        heading : "Infinity8.io NFT Marketplace",
        category:"MobileApp",
        Image : require('../../assets/images/Group 1000004048.png'),
        categoryName:"Mobile App",
    },
    {
        id : 4,
        casename: 'yuse-token',
        heading : "Logo Design creativity",
        category:"WebDevelopment",
        Image : require('../../assets/images/image 100.png'),
        categoryName:"Web Design",
    },
    {
        id : 5,
        casename: 'sinverse',
        heading : "Design is a creative part",
        category:"WebDevelopment",
        Image : require('../../assets/images/image 97.png'),
        categoryName:"Web Design",
    },
    {
        id : 6,
        casename: 'Hous',
        heading : "Getting tickets to the big show",
        category:"UI/UX",
        Image : require('../../assets/images/hous-mob-projectimg.png'),
        categoryName:"UI/UX",
    },
    {
        id : 7,
        casename: 'arthbit',
        heading : "Mobile App landing Design",
        category:"WebDevelopment",
        Image : require('../../assets/images/image 99.png'),
        categoryName:"Web Design",
        class : 'blue  project-boxes',
    },
    {
        id : 8,
        casename: 'ever-eth',
        heading : "EverETH Wallet App",
        category:"MobileApp",
        Image : require('../../assets/images/Group 1000004049.png'),
        categoryName:"Mobile App",
    },
    {
        id : 9,
        casename: 'quick-ask',
        heading : "Quick Ask",
        category:"LogoDesign",
        Image : require('../../assets/images/logo-img-1.svg').default,
        categoryName:"Logo Design",
    },
    {
        id : 10,
        casename: 'dog-ai',
        heading : "Dog AI",
        category:"LogoDesign",
        Image : require('../../assets/images/logo-img-2.svg').default,
        categoryName:"Logo Design",
    },
    {
        id : 11,
        casename: '99Cents',
        heading : "99Cents",
        category:"UI/UX",
        Image : require('../../assets/images/99cent-ui-projectimg.png'),
        categoryName:"UI/UX",
    },
    {
        id : 12,
        casename: 'Eduvo',
        heading : "Eduvo",
        category:"UI/UX",
        Image : require('../../assets/images/eduvo-ui-projectimg.png'),
        categoryName:"UI/UX",
    },
    {
        id : 13,
        casename: 'Luxury-candle',
        heading : "Luxury Candle Brands",
        category:"UI/UX",
        Image : require('../../assets/images/candle-ui-projectimg.png'),
        categoryName:"UI/UX",
    },
    {
        id : 14,
        casename: 'real-estate-app',
        heading : "Clio Real Estate App",
        category:"MobileApp",
        Image : require('../../assets/images/hous-mob-projectimg.png'),
        categoryName:"Mobile App",
    },
    {
        id : 15,
        casename: 'unicare-online-classes',
        heading : "Unicare Online Classes App",
        category:"MobileApp",
        Image : require('../../assets/images/unicare-mob-projectimg.png'),
        categoryName:"Mobile App",
    },
    {
        id : 16,
        casename: 'mindmate-aI',
        heading : "Mindmate AI Chatbot App",
        category:"MobileApp",
        Image : require('../../assets/images/mindmate-mob-projectimg.png'),
        categoryName:"Mobile App",
    },
    {
        id : 17,
        casename: 'coincash-mobile',
        heading : "Coincash Mobile Application",
        category:"MobileApp",
        Image : require('../../assets/images/coincash-mob-projectimg.png'),
        categoryName:"Mobile App",
    },
    {
        id : 18,
        casename: 'N3L-best',
        heading : "N3L-Best UAE Online Shopping Mobile Application",
        category:"MobileApp",
        Image : require('../../assets/images/n3l-mob-projectimg.png'),
        categoryName:"Mobile App",
    },
    {
        id : 19,
        casename: 'scarlett-global',
        heading : "Scarlett Global Mobile Application",
        category:"MobileApp",
        Image : require('../../assets/images/scarlet-mob-projectimg.svg').default,
        categoryName:"Mobile App",
    },
    {
        id : 20,
        casename: 'qwik-ask',
        heading : "QwikAsk Website Design UI/UX",
        category:"UI/UX",
        Image : require('../../assets/images/quickask-ui-projectimg.png'),
        categoryName:"UI/UX",
    },
    {
        id : 21,
        casename: 'rewrite-website',
        heading : "Rewrite Website UI/UX Design",
        category:"UI/UX",
        Image : require('../../assets/images/rewritr-ui-projectimg.png'),
        categoryName:"UI/UX",
    },
    {
        id : 22,
        casename: 'carent-web',
        heading : "Carent Web UI/UX Design",
        category:"UI/UX",
        Image : require('../../assets/images/carent-ui-projectimg.png'),
        categoryName:"UI/UX",
    },
    {
        id : 23,
        casename: 'healthcare',
        heading : "Healthcare",
        category:"UI/UX",
        Image : require('../../assets/images/healthcare-ui-projectimg.png'),
        categoryName:"UI/UX",
    },
    {
        id : 24,
        casename: 'bulking',
        heading : "Bulking Website UI/UX",
        category:"UI/UX",
        Image : require('../../assets/images/bulking-ui-projectimg.png'),
        categoryName:"UI/UX",
    },
    {
        id : 25,
        casename: 'fitness-pro',
        heading : "Fitness Pro UI/UX",
        category:"UI/UX",
        Image : require('../../assets/images/fitness-ui-projectimg.png'),
        categoryName:"UI/UX",
    },
    {
        id : 26,
        casename: 'gibraltar',
        heading : "Gibraltar Website UI/UX",
        category:"UI/UX",
        Image : require('../../assets/images/gibraltar-ui-projectimg.png'),
        categoryName:"UI/UX",
    },
    {
        id : 27,
        casename: 'hous',
        heading : "Hous Real Estate Logo Design",
        category:"LogoDesign",
        Image : require('../../assets/images/hous-logo-projectimg.png'),
        categoryName:"Logo Design",
    },
    {
        id : 28,
        casename: 'lernezy',
        heading : "Lernezy Logo Design",
        category:"LogoDesign",
        Image : require('../../assets/images/lerenzy-projectimg.png'),
        categoryName:"Logo Design",
    },
    {
        id : 29,
        casename: 'shft-in',
        heading : "Shft-in Logo Design",
        category:"LogoDesign",
        Image : require('../../assets/images/shftin-logo-projectimg.png'),
        categoryName:"Logo Design",
    },
    {
        id : 30,
        casename: 'royal-bookings',
        heading : "Royal Bookings Logo Design",
        category:"LogoDesign",
        Image : require('../../assets/images/royal-booking-projectimg.png'),
        categoryName:"Logo Design",
    },
    {
        id : 31,
        casename: 'whopme',
        heading : "WhopMe Logo Design",
        category:"LogoDesign",
        Image : require('../../assets/images/whopme-projectimg.png'),
        categoryName:"Logo Design",
    },
    {
        id : 32,
        casename: 'e-liquid',
        heading : "E-liquids Logo Design",
        category:"LogoDesign",
        Image : require('../../assets/images/e-liquid-projectimg.png'),
        categoryName:"Logo Design",
    },
    {
        id : 33,
        casename: 'yihills',
        heading : "Yhills Logo Design",
        category:"LogoDesign",
        Image : require('../../assets/images/yihills-projectimg.png'),
        categoryName:"Logo Design",
    },
    {
        id : 34,
        casename: 'izy-host',
        heading : "IzyHost",
        category:"UI/UX",
        Image : require('../../assets/images/izyhost-ui-projectimg.png'),
        categoryName:"UI/UX",
    },
]

export default ProjectApi