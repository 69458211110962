const detailpageApi = [
    { 
        id: "1",
        casename: 'shft-in',
        project_summary: true,
        web_project: true,
        clientwork_para: 'Elevate your urban living with Shft-In, a tech-forward accommodation platform redefining co-living for millennials. Experience hassle-free house hunting and a vibrant community lifestyle that complements your individuality, making Shft-In the epitome of convenient and culture-rich living.',
        bannerImg: require('../../assets/images/shift-in-bg.png'),
        bannerColor: "#000",
        text_direction: "left",
        bannerHeading:"Shft-In",
        bannerDetail:"Your Ultimate Real Estate Destination",
        color_pallete:[
            {
                id: 1,
                color_Code: "#F33E5E",        
            },
            {
                id: 2,
                color_Code: "#0E0E0E",        
            },
            {
                id: 3,
                color_Code: "#ffffff",        
            },
        ],
        mainPageImg:require('../../assets/images/shftin-detail-1.png'),
        secondImgHeading:"About Us",
        secondImg:require('../../assets/images/shftin-detail-2.png'),
        sumImg1:require('../../assets/images/shftin-detail-3.png'),
        projectsumHeadingmain: 'Project Summary',
        projectsumPara: 'Shft-In revolutionizes urban living with its technology-driven co-living platform, addressing the challenges of house hunting for millennials. By offering not just homes but immersive cultural spaces, Shft-In transforms the living experience, providing a hassle-free, community-driven lifestyle where residents can thrive on their terms.',
        projectsumHeading1:"Properties",
        sumImg2:require('../../assets/images/shftin-detail-4.png'),
        projectsumHeading2:"Contact Us",
        sumImg3:require('../../assets/images/shftin-detail-5.png'),
        ThirdImg:'',
        fourthImg:'',
        fifthImg:'',
        sumImg4: '',
        sumImg5: '',
    },
    { 
        id: "2",
        casename: 'easy-crypto',
        project_summary: false,
        web_project: true,
        clientwork_para: 'Simplify your cryptocurrency journey with EasyCrypto.com/AU. Unlock seamless access to the world of digital assets in Australia, where buying and selling cryptocurrencies becomes effortless. Trust EasyCrypto.com for a user-friendly and secure platform, making your cryptocurrency experience as smooth as it is rewarding.',
        bannerImg: require('../../assets/images/easyCrypto-bg.png'),
        bannerColor: "#5308b2",
        bannerHeading:"EASY CRYPTO",
        bannerDetail:"Diversify your portfolio with crypto assets and spread your risk across a selection of coins and bundles.",
        text_direction: "right",
        color_pallete:[
            {
                id: 1,
                color_Code: "#ed1747",        
            },
            {
                id: 2,
                color_Code: "#4a4a4a",        
            },
            {
                id: 3,
                color_Code: "#5308b2",        
            },
            {
                id: 4,
                color_Code: "#0DDABA",        
            },
        ],
        mainPageImg:require('../../assets/images/crypto-mainpage.png'),
        secondImgHeading:"Product",
        secondImg:require('../../assets/images/cryptoSite-img-2.png'),
        ThirdImgHeading:"Institutional Investors",
        ThirdImg:require('../../assets/images/cryptoSite-img-3.png'),
        // sumImg1:require('../../assets/images/cryptoSite-img-3.png'),
        fourthImgHeading: 'Resources',
        fourthImg:require('../../assets/images/cryptoSite-img-4.png'),
        fifthImgHeading: 'Main Page',
        fifthImg:require('../../assets/images/cryptoSite-img-3.png'),
        sumImg2: '',
        sumImg3: '',
        sumImg4: '',
        sumImg5: '',
    },
    { 
        id: "3",
        casename: 'infinity8.io',
        bannerImg: require('../../assets/images/mob-app-bg-1.png'),
        bannerColor: "#000",
        text_direction: "right",
        bannerHeading:"Infinity8.io  NFT Marketplace",
        bannerDetail:"",
        mob_project: true,
        clientwork_para: 'Elevate Infinity8.io, the groundbreaking Philanthropic NFT Marketplace, by optimizing user experience, introducing artist spotlights, real-time impact tracking, and collaborative art initiatives, fostering a deeper connection between art enthusiasts and creators while making a tangible difference in philanthropy. Our proposed enhancements aim to create a visually appealing, educational, and engaging platform, further solidifying Infinity8.io unique position at the intersection of fine and digital art.',
        color_pallete:[
            {
                id: 1,
                color_Code: "#144877",        
            },
            {
                id: 2,
                color_Code: "#007bff",        
            },
            {
                id: 3,
                color_Code: "#6610f2",        
            },
            {
                id: 4,
                color_Code: "#6f42c1",        
            },
            {
                id: 5,
                color_Code: "#e83e8c",        
            },
        ],
        screen_img : [
            {
                id: 1,
                img: require('../../assets/images/infinity-detailimg-1.svg').default,
            },
            {
                id: 2,
                img: require('../../assets/images/infinity-detailimg-2.svg').default,
            },
            {
                id: 3,
                img: require('../../assets/images/infinity-detailimg-3.svg').default,
            },
            {
                id: 4,
                img: require('../../assets/images/infinity-detailimg-4.svg').default,
            },
            {
                id: 5,
                img: require('../../assets/images/infinity-detailimg-5.svg').default,
            },
            {
                id: 6,
                img: require('../../assets/images/infinity-detailimg-6.svg').default,
            },
        ],
    },
    { 
        id: "4",
        casename: 'yuse-token',
        project_summary: true,
        web_project: true,
        clientwork_para: 'Elevate your digital presence with YuseToken innovative solutions, revolutionizing the way you engage with your audience. Experience the  future of decentralized marketing and communication at yusetoken.io',
        bannerImg: require('../../assets/images/yuse-bg.png'),
        bannerColor: "#000",
        text_direction: "left",
        bannerHeading:"YUSE TOKEN",
        bannerDetail:"A Multi-Utility Token",
        color_pallete:[
            {
                id: 1,
                color_Code: "#431A62",        
            },
            {
                id: 2,
                color_Code: "#B34EFF",        
            },
            {
                id: 3,
                color_Code: "#070A29",        
            },
            {
                id: 4,
                color_Code: "#ffffff",        
            },
        ],
        mainPageImg:require('../../assets/images/yuse-mainpage.png'),
        secondImgHeading:"About Us",
        secondImg:require('../../assets/images/yuseSite-img-2.png'),
        sumImg1:require('../../assets/images/yuseSum-img-1.png'),
        projectsumHeadingmain: 'Project Summary',
        projectsumPara: 'YuseToken presents a groundbreaking project, reshaping digital interactions through innovative solutions. Revolutionize your brand&apos;s narrative with decentralized marketing and communication, ushering in a new era of engaging experiences. Explore the limitless possibilities and transformative impact of YuseToken at yusetoken.io',
        projectsumHeading1:"About Us",
        sumImg2:require('../../assets/images/yuseSum-img-2.png'),
        projectsumHeading2:"About Us",
        sumImg3:require('../../assets/images/yuseSum-img-3.png'),
        ThirdImg:'',
        fourthImg:'',
        fifthImg:'',
        sumImg4: '',
        sumImg5: '',
    },
    { 
        id: "5",
        casename: 'sinverse',
        project_summary: true,
        web_project: true,
        clientwork_para: 'Step into the future of financial empowerment with Sinverse.com. As your trusted partner, we offer innovative solutions to optimize your financial journey. Explore our platform at https://sinverse.com/home and experience the seamless integration of technology and finance, designed to elevate your financial well-being.',
        bannerImg: require('../../assets/images/sineverse-bg.png'),
        bannerColor: "#1f0049",
        bannerHeading:"SINVERSE Website",
        bannerDetail:"Creating memorable and interactive UX and UI designs",
        text_direction: "right",
        color_pallete:[
            {
                id: 1,
                color_Code: "#144877",        
            },
            {
                id: 2,
                color_Code: "#007bff",        
            },
            {
                id: 3,
                color_Code: "#6610f2",        
            },
            {
                id: 4,
                color_Code: "#6f42c1",        
            },
            {
                id: 5,
                color_Code: "#e83e8c",        
            },
        ],
        mainPageImg:require('../../assets/images/sinverse-mainpage.png'),
        secondImgHeading:"Marketplace",
        secondImg:require('../../assets/images/sinverseSite-img-2.png'),
        ThirdImgHeading:"Land",
        ThirdImg:require('../../assets/images/sinverseSite-img-3.png'),
        sumImg1:require('../../assets/images/sinversSum-img1.png'),
        projectsumHeadingmain: 'Project Summary',
        projectsumPara: 'Sinverse.com introduces a paradigm shift in financial empowerment, presenting innovative solutions to enhance your financial journey. Our platform, at the forefront of technology and finance integration, is designed to optimize and elevate your financial well-being. Explore the future of finance with Sinverse.com, where empowerment meets innovation for a transformative financial experience.',
        projectsumHeading1:"License",
        sumImg2:require('../../assets/images/sinversSum-img2.png'),
        projectsumHeading2:"Other NFTs",
        sumImg3:require('../../assets/images/sinversSum-img3.png'),
        projectsumHeading3:"Gold Coins",
        sumImg4:require('../../assets/images/sinversSum-img4.png'),
        fourthImg:'',
        fifthImg:'',
        sumImg5: '',
    },
    { 
        id: "6",
        casename: 'Hous',
        project_summary: true,
        web_project: true,
        clientwork_para: 'Lorem ipsum dolor sit amet consectetur. Nibh dolor aliquam mauris at suscipit volutpat ornare. Pretium felis vitae adipiscing sed dignissim elementum. Et bibendum placerat dolor adipiscing sed cursus.',
        bannerImg: require('../../assets/images/hous-banner-bg.png'),
        bannerColor: "#000",
        text_direction: "left",
        bannerHeading:"Hous",
        bannerDetail:"Revolutionizing Real Estate Search & Transactions with Hous",
        color_pallete:[
            {
                id: 1,
                color_Code: "#76D6D2",        
            },
            {
                id: 2,
                color_Code: "#14211C",        
            },
            {
                id: 3,
                color_Code: "#ffffff",        
            },
        ],
        mainPageImg:require('../../assets/images/hous-detailimg-1.png'),
        secondImgHeading:"Property Overview",
        fourthImgHeading: 'Transaction',
        fifthImgHeading: 'Profile',
        ThirdImgHeading: 'Profile',
        secondImg:require('../../assets/images/hous-detailimg-2.png'),
        ThirdImg:require('../../assets/images/hous-detailimg-3.png'),
        fourthImg:require('../../assets/images/hous-detailimg-4.png'),
        fifthImg:require('../../assets/images/hous-detailimg-5.png'),
        sumImg1:require('../../assets/images/hous-detailimg-6.png'),
        projectsumHeadingmain: 'Project Summary',
        projectsumPara: 'The Terminal Technology team provided consulting, integration, and development services for the BMW Performance Center Marketing websites. This includes 3 Websites – BMW Driving School, BMW U.S. Rider Academy, MINI Driving Experiences.',
        projectsumHeading1:"Dues",
        sumImg2:require('../../assets/images/hous-detailimg-7.png'),
        projectsumHeading2:"New Agreement",
        sumImg3:require('../../assets/images/hous-detailimg-8.png'),
        sumImg4: '',
        sumImg5: '',
    },
    { 
        id: "7",
        casename: 'arthbit',
        project_summary: true,
        web_project: true,
        clientwork_para: 'Dive into the world of art and finance seamlessly with Arthbit.com. This innovative platform bridges the gap between art and blockchain, offering a unique space to buy, sell, and invest in digital art. Elevate your art journey with Arthbit.com, where creativity meets cutting-edge technology for a transformative experience.',
        bannerImg: require('../../assets/images/arthbit-bg-img.png'),
        bannerColor: "#fff",
        bannerHeading:"ARTHBIT",
        bannerDetail:"Diversify your portfolio with crypto assets and spread your risk across a selection of coins and bundles.",
        text_direction: "left",
        text_color: 'text-dark-200',
        banner_white: "white",
        color_pallete:[
            {
                id: 1,
                color_Code: "#23262f",        
            },
            {
                id: 2,
                color_Code: "#777eac",        
            },
            {
                id: 3,
                color_Code: "#f4f5f6",        
            },
            {
                id: 4,
                color_Code: "#ffffff",        
            },
        ],
        mainPageImg:require('../../assets/images/arthbit-mainpage.png'),
        secondImgHeading:"Marketplace",
        secondImg:require('../../assets/images/arthbitSite-img-2.png'),
        sumImg1:require('../../assets/images/arthbitSum-img1.png'),
        projectsumHeadingmain: 'Project Summary',
        projectsumPara: 'Arthbit.com pioneers the convergence of art and blockchain, presenting an avant-garde platform that redefines the art market. Our project seamlessly integrates creativity with cutting-edge technology, providing a space where digital art can be bought, sold, and invested in. Experience the future of art and finance synergy with Arthbit.com, a transformative platform at the intersection of innovation and artistic expression.',
        projectsumHeading1:"Staking",
        sumImg2:require('../../assets/images/arthbitSum-img2.png'),
        projectsumHeading2:"About Us",
        sumImg3:require('../../assets/images/arthbitSum-img3.png'),
        projectsumHeading3:"Login",
        sumImg4:require('../../assets/images/arthbitSum-img4.png'),
        ThirdImg:'',
        fourthImg:'',
        fifthImg:'',
        sumImg5: '',
    },
    { 
        id: "8",
        casename: 'ever-eth',
        bannerImg: require('../../assets/images/mob-app-bg-2.png'),
        bannerColor: "#2c1e64",
        text_direction: "right",
        bannerHeading:"EverETH  Wallet App",
        bannerDetail:"",
        mob_project: true,
        clientwork_para: 'Empower EverETHs cutting-edge dApp and protocol ecosystem with a refined brand identity, engaging content, and transparent communication, maximizing community involvement and highlighting the unique dividend distribution model to shareholders for sustained growth and success.',
        color_pallete:[
            {
                id: 1,
                color_Code: "#144877",        
            },
            {
                id: 2,
                color_Code: "#007bff",        
            },
            {
                id: 3,
                color_Code: "#6610f2",        
            },
            {
                id: 4,
                color_Code: "#6f42c1",        
            },
            {
                id: 5,
                color_Code: "#e83e8c",        
            },
        ],
        screen_img : [
            {
                id: 1,
                img: require('../../assets/images/wallet-detailimg-1.svg').default,
            },
            {
                id: 2,
                img: require('../../assets/images/wallet-detailimg-2.svg').default,
            },
            {
                id: 3,
                img: require('../../assets/images/wallet-detailimg-3.svg').default,
            },
            {
                id: 4,
                img: require('../../assets/images/wallet-detailimg-4.svg').default,
            },
            {
                id: 5,
                img: require('../../assets/images/wallet-detailimg-5.svg').default,
            },
            {
                id: 6,
                img: require('../../assets/images/wallet-detailimg-6.svg').default,
            },
        ],
    },
    {
        id : "9",
        casename: 'quick-ask',
        logo_img1: require('../../assets/images/logo-detailimg-1.png'),
    },
    {
        id : "10",
        casename: 'dog-ai',
        logo_img1: require('../../assets/images/logo2-detailimg-1.png'),
    },
    { 
        id: "11",
        casename: '99Cents',
        project_summary: true,
        web_project: true,
        clientwork_para: 'Unlock seamless web hosting with 99cents.host – where affordability meets reliability. Elevate your online presence effortlessly with budget-friendly hosting solutions that ensure your website stays fast, secure, and accessible to the world.',
        bannerImg: require('../../assets/images/99cent-banner.png'),
        bannerColor: "#000",
        text_direction: "left",
        bannerHeading:"99Cents",
        bannerDetail:"Revolutionizing Real Estate Search & Transactions with Hous",
        color_pallete:[
            {
                id: 1,
                color_Code: "#0060FE",        
            },
            {
                id: 2,
                color_Code: "#0E0E0E",        
            },
            {
                id: 3,
                color_Code: "#ffffff",        
            },
        ],
        mainPageImg:require('../../assets/images/99cent-detailImg-1.png'),
        secondImgHeading:"About Us",
        fourthImgHeading: '',
        fifthImgHeading: '',
        ThirdImgHeading: '',
        secondImg:require('../../assets/images/99cent-detailImg-2.png'),
        ThirdImg: '',
        fourthImg: '',
        fifthImg: '',
        sumImg1:require('../../assets/images/99cent-detailImg-3.png'),
        projectsumHeadingmain: 'Project Summary',
        projectsumPara: '99cents.host redefines web hosting, providing cost-effective yet dependable solutions for individuals and businesses alike. With a focus on affordability, reliability, and performance, our platform empowers users to establish and maintain a robust online presence effortlessly. Experience hosting that goes beyond the ordinary, ensuring your website remains secure, fast, and accessible at an unparalleled value.',
        projectsumHeading1:"Properties",
        sumImg2:require('../../assets/images/99cent-detailImg-4.png'),
        projectsumHeading2:"Contact Us",
        sumImg3:require('../../assets/images/99cent-detailImg-5.png'),
        sumImg4: '',
        sumImg5: '',
    },
    { 
        id: "12",
        casename: 'Eduvo',
        project_summary: true,
        web_project: true,
        clientwork_para: 'Embark on a transformative educational journey with Eduvo. Harnessing cutting-edge technology, Eduvo delivers a personalized learning experience tailored to your needs. Explore a world of knowledge, innovation, and skill development at https://eduvo.us, where education meets empowerment.',
        bannerImg: require('../../assets/images/eduvo-banner.png'),
        bannerColor: "#432100",
        text_direction: "left",
        bannerHeading:"Eduvo",
        bannerDetail:"Revolutionizing Real Estate Search & Transactions with Hous",
        color_pallete:[
            {
                id: 1,
                color_Code: "#0060FE",        
            },
            {
                id: 2,
                color_Code: "#0E0E0E",        
            },
            {
                id: 3,
                color_Code: "#ffffff",        
            },
        ],
        mainPageImg:require('../../assets/images/eduvo-detailImg-1.png'),
        secondImgHeading:"About Us",
        fourthImgHeading: '',
        fifthImgHeading: '',
        ThirdImgHeading: '',
        secondImg:require('../../assets/images/eduvo-detailImg-2.png'),
        ThirdImg: '',
        fourthImg: '',
        fifthImg: '',
        sumImg1:require('../../assets/images/eduvo-detailImg-3.png'),
        projectsumHeadingmain: 'Project Summary',
        projectsumPara: 'Eduvo revolutionizes education through its innovative platform, offering personalized learning experiences driven by advanced technology. With a commitment to empowerment, Eduvo.us opens doors to a world of knowledge and skill development. Explore the future of education with us, where individualized learning meets cutting-edge innovation.',
        projectsumHeading1:"Properties",
        sumImg2:require('../../assets/images/eduvo-detailImg-4.png'),
        projectsumHeading2:"Contact Us",
        sumImg3:require('../../assets/images/eduvo-detailImg-5.png'),
        sumImg4: '',
        sumImg5: '',
    },
    { 
        id: "13",
        casename: 'Luxury-candle',
        project_summary: true,
        web_project: true,
        clientwork_para: 'Indulge in the essence of luxury with LuxuryCandleBrands.com, your premier destination for exquisite candle experiences. Immerse yourself in a curated selection of the finest candle brands, where each fragrance tells a unique story of opulence and sophistication. Elevate your ambiance at https://luxurycandlebrands.com, where every candle is a statement of refined taste and elegance.',
        bannerImg: require('../../assets/images/candle-banner.png'),
        bannerColor: "#000",
        text_direction: "left",
        bannerHeading:"Luxury Candle Brands",
        bannerDetail:"Revolutionizing Real Estate Search & Transactions with Hous",
        color_pallete:[
            {
                id: 1,
                color_Code: "#0060FE",        
            },
            {
                id: 2,
                color_Code: "#0E0E0E",        
            },
            {
                id: 3,
                color_Code: "#ffffff",        
            },
        ],
        mainPageImg:require('../../assets/images/candle-detailImg-1.png'),
        secondImgHeading:"About Us",
        fourthImgHeading: '',
        fifthImgHeading: '',
        ThirdImgHeading: '',
        secondImg:require('../../assets/images/candle-detailImg-2.png'),
        ThirdImg: '',
        fourthImg: '',
        fifthImg: '',
        sumImg1:require('../../assets/images/candle-detailImg-3.png'),
        projectsumHeadingmain: 'Project Summary',
        projectsumPara: 'Experience the epitome of luxury at LuxuryCandleBrands.com, a curated platform showcasing the finest candle brands. Our project aims to redefine ambiance, offering a carefully selected collection that embodies opulence and sophistication. With each fragrance, we invite you to immerse yourself in a world where candles become a symbol of refined taste and elevate your surroundings to the height of elegance. Explore the curated essence of luxury at https://luxurycandlebrands.com.',
        projectsumHeading1:"Properties",
        sumImg2:require('../../assets/images/candle-detailImg-4.png'),
        projectsumHeading2:"Contact Us",
        sumImg3:require('../../assets/images/candle-detailImg-5.png'),
        sumImg4: '',
        sumImg5: '',
    },
    {
        id : 14,
        casename: 'real-estate-app',
        bannerImg: require('../../assets/images/realestate-mob-banner.svg').default,
        bannerColor: "#37a495",
        text_direction: "center",
        bannerHeading:"Clio Real Estate App",
        bannerDetail:"",
        mob_project: true,
        clientwork_para: 'Elevate your companys narrative by crafting a compelling story that harmonizes technological innovation with profound purpose, resonating authentically with your audience and fostering a lasting positive impact. Through strategic communication and visual identity enhancement, we aim to amplify your unique vision, creating a genuine connection with those who share your transformative goals.',
        color_pallete:[
            {
                id: 1,
                color_Code: "#53D0BF",        
            },
            {
                id: 2,
                color_Code: "#191517",        
            },
            {
                id: 3,
                color_Code: "#EAE4FF",        
            },
            {
                id: 4,
                color_Code: "#FFEDDF",        
            },
            {
                id: 5,
                color_Code: "#D8F2DC",        
            },
        ],
        screen_img : [
            {
                id: 1,
                img: require('../../assets/images/realestate-mob-img1.svg').default,
            },
            {
                id: 2,
                img: require('../../assets/images/realestate-mob-img2.svg').default,
            },
            {
                id: 3,
                img: require('../../assets/images/realestate-mob-img3.svg').default,
            },
            {
                id: 4,
                img: require('../../assets/images/realestate-mob-img4.svg').default,
            },
            {
                id: 5,
                img: require('../../assets/images/realestate-mob-img5.svg').default,
            },
            {
                id: 6,
                img: require('../../assets/images/realestate-mob-img6.svg').default,
            },
            {
                id: 7,
                img: require('../../assets/images/realestate-mob-img7.svg').default,
            },
            {
                id: 8,
                img: require('../../assets/images/realestate-mob-img8.svg').default,
            },
            {
                id: 9,
                img: require('../../assets/images/realestate-mob-img9.svg').default,
            },
        ],
    },
    {
        id : 15,
        casename: 'unicare-online-classes',
        bannerImg: require('../../assets/images/unicare-mob-banner.png'),
        bannerColor: "#482496",
        text_direction: "left",
        bannerHeading:"Unicare Online Classes App",
        bannerDetail:"",
        mob_project: true,
        clientwork_para: 'Elevate Unicare Fresh booking app with an intuitive interface, efficient booking system, and a user-friendly cancelable space feature, ensuring seamless reservations and heightened user satisfaction. Our enhancements aim to optimize the user experience, fostering increased engagement and convenience for all users.',
        color_pallete:[
            {
                id: 1,
                color_Code: "#6C5DD3",        
            },
            {
                id: 2,
                color_Code: "#191517",        
            },
            {
                id: 3,
                color_Code: "#5DD8D0",        
            },
            {
                id: 4,
                color_Code: "#FFFFFF",        
            },
        ],
        screen_img : [
            {
                id: 1,
                img: require('../../assets/images/unicare-mob-img1.svg').default,
            },
            {
                id: 2,
                img: require('../../assets/images/unicare-mob-img2.svg').default,
            },
            {
                id: 3,
                img: require('../../assets/images/unicare-mob-img3.svg').default,
            },
            {
                id: 4,
                img: require('../../assets/images/unicare-mob-img4.svg').default,
            },
            {
                id: 5,
                img: require('../../assets/images/unicare-mob-img5.svg').default,
            },
            {
                id: 6,
                img: require('../../assets/images/unicare-mob-img6.svg').default,
            },
            {
                id: 7,
                img: require('../../assets/images/unicare-mob-img7.svg').default,
            },
            {
                id: 8,
                img: require('../../assets/images/unicare-mob-img8.svg').default,
            },
            {
                id: 9,
                img: require('../../assets/images/unicare-mob-img9.svg').default,
            },
        ],
    },
    {
        id : 16,
        casename: 'mindmate-aI',
        bannerImg: require('../../assets/images/mindmate-banner.svg').default,
        bannerColor: "#482496",
        text_direction: "left",
        bannerHeading:"Mindmate AI Chatbot App",
        bannerDetail:"",
        mob_project: true,
        clientwork_para: 'Elevate your creative journey with Mindmate, an innovative AI Chat Assistant using advanced natural language processing. Whether you re a writer, student, or professional, Mindmate prompt-powered system and premium UI Kit ensure a seamless, inspiring experience for generating ideas and exploring new perspectives.',
        color_pallete:[
            {
                id: 1,
                color_Code: "#A7BA88",        
            },
            {
                id: 2,
                color_Code: "#191517",        
            },
            {
                id: 3,
                color_Code: "#9E9E9E",        
            },
            {
                id: 4,
                color_Code: "#FFFFFF",        
            },
        ],
        screen_img : [
            {
                id: 1,
                img: require('../../assets/images/mindmate-mob-img1.svg').default,
            },
            {
                id: 2,
                img: require('../../assets/images/mindmate-mob-img2.svg').default,
            },
            {
                id: 3,
                img: require('../../assets/images/mindmate-mob-img3.svg').default,
            },
            {
                id: 4,
                img: require('../../assets/images/mindmate-mob-img4.svg').default,
            },
            {
                id: 5,
                img: require('../../assets/images/mindmate-mob-img5.svg').default,
            },
            {
                id: 6,
                img: require('../../assets/images/mindmate-mob-img6.svg').default,
            },
            {
                id: 7,
                img: require('../../assets/images/mindmate-mob-img7.svg').default,
            },
            {
                id: 8,
                img: require('../../assets/images/mindmate-mob-img8.svg').default,
            },
            {
                id: 9,
                img: require('../../assets/images/mindmate-mob-img9.svg').default,
            },
        ],
    },
    {
        id : 17,
        casename: 'coincash-mobile',
        bannerImg: require('../../assets/images/coincash-banner.svg').default,
        bannerColor: "#000",
        text_direction: "left",
        bannerHeading:"Coincash Mobile Application",
        bannerDetail:"",
        mob_project: true,
        clientwork_para: 'Facilitating secure cryptocurrency exchanges, Coincash empowers users to seamlessly trade bitcoins, ethereum, or USDT for fiat currencies, and vice versa. With a strict KYC process aligned with predetermined limits, Coincash ensures a trustworthy platform for swift and reliable transactions without holding users cryptocurrencies.',
        color_pallete:[
            {
                id: 1,
                color_Code: "#E2A515",        
            },
            {
                id: 2,
                color_Code: "#191517",        
            },
            {
                id: 3,
                color_Code: "#4CB051",        
            },
            {
                id: 4,
                color_Code: "#FFFFFF",        
            },
        ],
        screen_img : [
            {
                id: 1,
                img: require('../../assets/images/coincash-mob-img1.svg').default,
            },
            {
                id: 2,
                img: require('../../assets/images/coincash-mob-img2.svg').default,
            },
            {
                id: 3,
                img: require('../../assets/images/coincash-mob-img3.svg').default,
            },
            {
                id: 4,
                img: require('../../assets/images/coincash-mob-img4.svg').default,
            },
            {
                id: 5,
                img: require('../../assets/images/coincash-mob-img5.svg').default,
            },
            {
                id: 6,
                img: require('../../assets/images/coincash-mob-img6.svg').default,
            },
        ],
    },
    {
        id : 18,
        casename: 'N3L-best',
        bannerImg: require('../../assets/images/n3l-banner.svg').default,
        bannerColor: "#000",
        text_direction: "left",
        bannerHeading:"N3L-Best UAE Online Shopping Mobile Application",
        bannerDetail:"",
        mob_project: true,
        clientwork_para: 'For residents in the UAE, n3l is your ultimate destination for daily deals and top discounts on any online platform. The n3l.ae app ensures convenience with diverse payment options, including cash on delivery, credit card, or PayPal, complemented by a hassle-free return policy.',
        color_pallete:[
            {
                id: 1,
                color_Code: "#FBBF0A",        
            },
            {
                id: 2,
                color_Code: "#3D3D3D",        
            },
            {
                id: 3,
                color_Code: "#898989",        
            },
            {
                id: 4,
                color_Code: "#FFFFFF",        
            },
        ],
        screen_img : [
            {
                id: 1,
                img: require('../../assets/images/n3l-mob-img1.png'),
            },
            {
                id: 2,
                img: require('../../assets/images/n3l-mob-img2.svg').default,
            },
            {
                id: 3,
                img: require('../../assets/images/n3l-mob-img3.svg').default,
            },
            {
                id: 4,
                img: require('../../assets/images/n3l-mob-img4.png'),
            },
            {
                id: 5,
                img: require('../../assets/images/n3l-mob-img5.svg').default,
            },
        ],
    },
    {
        id : 19,
        casename: 'scarlett-global',
        bannerImg: require('../../assets/images/scarlet-banner.svg').default,
        bannerColor: "#3a98a3",
        text_direction: "left",
        bannerHeading:"Scarlett Global Mobile Application",
        bannerDetail:"",
        mob_project: true,
        clientwork_para: 'Scarlett Global, the avant-garde ICO and real estate marketplace at https://scarlettglobal.io, coupled with its standalone Crypto Exchange, introduces unmatched innovation to the cryptocurrency landscape, setting a new standard for excellence.',
        color_pallete:[
            {
                id: 1,
                color_Code: "#0098A9",        
            },
            {
                id: 2,
                color_Code: "#AFAEB3",        
            },
            {
                id: 3,
                color_Code: "#001818",        
            },
            {
                id: 4,
                color_Code: "#FFFFFF",        
            },
        ],
        screen_img : [
            {
                id: 1,
                img: require('../../assets/images/scarlet-mob-img1.svg').default,
            },
            {
                id: 2,
                img: require('../../assets/images/scarlet-mob-img2.svg').default,
            },
            {
                id: 3,
                img: require('../../assets/images/scarlet-mob-img3.svg').default,
            },
            {
                id: 4,
                img: require('../../assets/images/scarlet-mob-img4.svg').default,
            },
            {
                id: 5,
                img: require('../../assets/images/scarlet-mob-img5.svg').default,
            },
            {
                id: 6,
                img: require('../../assets/images/scarlet-mob-img6.svg').default,
            },
            {
                id: 7,
                img: require('../../assets/images/scarlet-mob-img7.svg').default,
            },
            {
                id: 8,
                img: require('../../assets/images/scarlet-mob-img8.svg').default,
            },
        ],
    },
    { 
        id: 20,
        casename: 'qwik-ask',
        project_summary: true,
        web_project: true,
        clientwork_para: 'Lorem ipsum dolor sit amet consectetur. Nibh dolor aliquam mauris at suscipit volutpat ornare. Pretium felis vitae adipiscing sed dignissim elementum. Et bibendum placerat dolor adipiscing sed cursus.',
        bannerImg: require('../../assets/images/qwikask-banner.svg').default,
        bannerColor: "#a14ed3",
        text_direction: "right",
        bannerHeading:"QwikAsk Website Design UI/UX",
        bannerDetail:"Write 10x faster, engage your audience and never struggle with the blank page again. The number 1 AI collaborative software you ever need.",
        color_pallete:[
            {
                id: 1,
                color_Code: "#9E69F3",        
            },
            {
                id: 2,
                color_Code: "#F3C02B",        
            },
            {
                id: 3,
                color_Code: "#CF265A",        
            },
            {
                id: 4,
                color_Code: "#141414",        
            },
        ],
        mainPageImg:require('../../assets/images/qwik-detail-1.png'),
        secondImgHeading:"UseCases",
        fourthImgHeading: '',
        fifthImgHeading: '',
        ThirdImgHeading: '',
        secondImg:require('../../assets/images/qwik-detail-2.png'),
        ThirdImg: '',
        fourthImg: '',
        fifthImg: '',
        sumImg1:require('../../assets/images/qwik-detail-3.png'),
        projectsumHeadingmain: 'Pricing',
        projectsumPara: '',
        projectsumHeading1:"Blogs",
        sumImg2:require('../../assets/images/qwik-detail-4.png'),
        projectsumHeading2:"Login",
        sumImg3:require('../../assets/images/qwik-detail-5.png'),
        sumImg4: '',
        sumImg5: '',
    },
    { 
        id: 21,
        casename: 'rewrite-website',
        project_summary: false,
        web_project: true,
        clientwork_para: 'Lorem ipsum dolor sit amet consectetur. Nibh dolor aliquam mauris at suscipit volutpat ornare. Pretium felis vitae adipiscing sed dignissim elementum. Et bibendum placerat dolor adipiscing sed cursus.',
        bannerImg: require('../../assets/images/rewrite-website-banner.png'),
        bannerColor: "#3f9183",
        text_direction: "left",
        bannerHeading:"Rewrite Website UI/UX Design",
        bannerDetail:"One platform to generate all AI contents & AI Voiceovers",
        color_pallete:[
            {
                id: 1,
                color_Code: "#3F9183",        
            },
            {
                id: 2,
                color_Code: "#FFD900",        
            },
            {
                id: 3,
                color_Code: "#070707",        
            },
            {
                id: 4,
                color_Code: "#ffffff",        
            },
        ],
        mainPageImg:require('../../assets/images/rewrite-main-page-img.png'),
        secondImgHeading:"Login",
        ThirdImgHeading: 'Sign Up',
        fourthImgHeading: '',
        fifthImgHeading: '',
        secondImg:require('../../assets/images/rewrite-login.png'),
        ThirdImg:require('../../assets/images/rewrite-signup.png'),
        fourthImg: '',
        fifthImg: '',
    },
    { 
        id: 22,
        casename: 'carent-web',
        project_summary: true,
        web_project: true,
        clientwork_para: 'Lorem ipsum dolor sit amet consectetur. Nibh dolor aliquam mauris at suscipit volutpat ornare. Pretium felis vitae adipiscing sed dignissim elementum. Et bibendum placerat dolor adipiscing sed cursus.',
        bannerImg: require('../../assets/images/carent-web-banner.svg').default,
        bannerColor: "#000",
        text_direction: "left",
        bannerHeading:"Carent Web UI/UX Design",
        bannerDetail:"Discover the Joy of Flexible and Affordable Car Rentals",
        color_pallete:[
            {
                id: 1,
                color_Code: "#000000",        
            },
            {
                id: 2,
                color_Code: "#EAFE89",        
            },
            {
                id: 3,
                color_Code: "#1e19d8",        
            },
            {
                id: 4,
                color_Code: "#ffffff",        
            },
        ],
        mainPageImg:require('../../assets/images/carent-detail-1.png'),
        secondImgHeading:"About Us",
        ThirdImgHeading: '',
        fourthImgHeading: '',
        fifthImgHeading: '',
        secondImg:require('../../assets/images/carent-detail-2.png'),
        ThirdImg: '',
        fourthImg: '',
        fifthImg: '',
        sumImg1:require('../../assets/images/carent-detail-3.png'),
        projectsumHeadingmain: 'How It Works',
        projectsumPara: '',
        projectsumHeading1:"FAQ",
        sumImg2:require('../../assets/images/carent-detail-4.png'),
        projectsumHeading2:"Careers",
        projectsumHeading4:"Contact Us",
        sumImg3:require('../../assets/images/carent-detail-5.png'),
        sumImg4: '',
        sumImg5: require('../../assets/images/carent-detail-6.png'),
    },
    { 
        id: 23,
        casename: 'healthcare',
        project_summary: true,
        web_project: true,
        clientwork_para: 'Lorem ipsum dolor sit amet consectetur. Nibh dolor aliquam mauris at suscipit volutpat ornare. Pretium felis vitae adipiscing sed dignissim elementum. Et bibendum placerat dolor adipiscing sed cursus.',
        banner_white: "white",
        bannerImg: require('../../assets/images/healthcare-banner.png'),
        bannerColor: "#f0fae9",
        text_direction: "left",
        bannerHeading:"Healthcare",
        text_color: "text-dark-200",
        bannerDetail:"We're pioneering the future of healthcare with cutting-edge telehealth and telemedicine solution",
        color_pallete:[
            {
                id: 1,
                color_Code: "#84CC16",        
            },
            {
                id: 2,
                color_Code: "#2F3C33",        
            },
            {
                id: 3,
                color_Code: "#F5F5F5",        
            },
            {
                id: 4,
                color_Code: "#F43F5E",        
            },
        ],
        mainPageImg:require('../../assets/images/healthcare-detail-1.png'),
        secondImgHeading:"Services",
        fourthImgHeading: '',
        fifthImgHeading: '',
        ThirdImgHeading: '',
        secondImg:require('../../assets/images/healthcare-detail-2.png'),
        ThirdImg: '',
        fourthImg: '',
        fifthImg: '',
        sumImg1: require('../../assets/images/healthcare-detail-3.png'),
        projectsumHeadingmain: 'How it Works',
        projectsumPara: '',
        projectsumHeading1:"Careers",
        sumImg2:require('../../assets/images/healthcare-detail-4.png'),
        projectsumHeading2:"FAQ",
        projectsumHeading3:"Contact Us",
        sumImg3:require('../../assets/images/healthcare-detail-5.png'),
        sumImg4: require('../../assets/images/healthcare-detail-6.png'),
        sumImg5: '',
    },
    { 
        id: 24,
        casename: 'bulking',
        project_summary: true,
        web_project: true,
        clientwork_para: 'Lorem ipsum dolor sit amet consectetur. Nibh dolor aliquam mauris at suscipit volutpat ornare. Pretium felis vitae adipiscing sed dignissim elementum. Et bibendum placerat dolor adipiscing sed cursus.',
        bannerImg: require('../../assets/images/bulking-banner.png'),
        bannerColor: "#064098",
        text_direction: "left",
        bannerHeading:"Bulking Website UI/UX",
        bannerDetail:`"It doesn’t matter if your goal is to get stronger, burn fat, or to
        just stay fit our world class coaches will guide you every step of
        the way."`,
        color_pallete:[
            {
                id: 1,
                color_Code: "#064BB4",        
            },
            {
                id: 2,
                color_Code: "#BCFF5E",        
            },
            {
                id: 3,
                color_Code: "#1C1C1E",        
            },
            {
                id: 4,
                color_Code: "#ffffff",        
            },
        ],
        mainPageImg:require('../../assets/images/bulking-detail-1.png'),
        secondImgHeading:"Program",
        ThirdImgHeading: '',
        fourthImgHeading: '',
        fifthImgHeading: '',
        secondImg:require('../../assets/images/bulking-detail-2.png'),
        ThirdImg: '',
        fourthImg: '',
        fifthImg: '',
        sumImg1:require('../../assets/images/bulking-detail-3.png'),
        projectsumHeadingmain: 'Trainers',
        projectsumPara: '',
        projectsumHeading1:"Membership",
        sumImg2:require('../../assets/images/bulking-detail-4.png'),
        projectsumHeading2:"Contact Us",
        sumImg3:require('../../assets/images/bulking-detail-5.png'),
        sumImg4: '',
        sumImg5: '',
    },
    { 
        id: 25,
        casename: 'fitness-pro',
        project_summary: true,
        web_project: true,
        clientwork_para: 'Lorem ipsum dolor sit amet consectetur. Nibh dolor aliquam mauris at suscipit volutpat ornare. Pretium felis vitae adipiscing sed dignissim elementum. Et bibendum placerat dolor adipiscing sed cursus.',
        bannerImg: require('../../assets/images/fitness-pro-banner.png'),
        bannerColor: "#57BA7A",
        text_direction: "left",
        bannerHeading:"Fitness Pro Web UI/UX",
        bannerDetail:"Track your workouts, get better results, and be the best version of you. Less thinking, more lifting.",
        color_pallete:[
            {
                id: 1,
                color_Code: "#57BA7A",        
            },
            {
                id: 2,
                color_Code: "#FF592C",        
            },
            {
                id: 3,
                color_Code: "#3B404C",        
            },
            {
                id: 4,
                color_Code: "#3B75FE",        
            },
        ],
        mainPageImg:require('../../assets/images/fitness-detail-1.png'),
        secondImgHeading:"Features",
        ThirdImgHeading: '',
        fourthImgHeading: '',
        fifthImgHeading: '',
        secondImg:require('../../assets/images/fitness-detail-2.png'),
        ThirdImg: '',
        fourthImg: '',
        fifthImg: '',
        sumImg1:require('../../assets/images/fitness-detail-3.png'),
        projectsumHeadingmain: 'How it Works',
        projectsumPara: '',
        projectsumHeading1:"Classes",
        sumImg2:require('../../assets/images/fitness-detail-4.png'),
        projectsumHeading2:"Classes",
        sumImg3:require('../../assets/images/fitness-detail-5.png'),
        sumImg4: '',
        sumImg5: '',
    },
    { 
        id: 26,
        casename: 'gibraltar',
        project_summary: true,
        web_project: true,
        clientwork_para: 'Lorem ipsum dolor sit amet consectetur. Nibh dolor aliquam mauris at suscipit volutpat ornare. Pretium felis vitae adipiscing sed dignissim elementum. Et bibendum placerat dolor adipiscing sed cursus.',
        bannerImg: require('../../assets/images/gibraltar-banner.png'),
        bannerColor: "#199E6D",
        text_direction: "left",
        bannerHeading:"Gibraltar Website UI/UX",
        bannerDetail:`"Walk through the Gibraltar Nature Reserve at your own leisure 
        with access to 17 attractions, all its wildlife and trails."`,
        color_pallete:[
            {
                id: 1,
                color_Code: "#199E6D",        
            },
            {
                id: 2,
                color_Code: "#F0F0EA",        
            },
            {
                id: 3,
                color_Code: "#0E0E0E",        
            },
            {
                id: 4,
                color_Code: "#ffffff",        
            },
        ],
        mainPageImg:require('../../assets/images/gibraltar-detail-1.png'),
        secondImgHeading:"About Us",
        fourthImgHeading:'',
        fifthImgHeading: '',
        ThirdImgHeading: '',
        secondImg:require('../../assets/images/gibraltar-detail-2.png'),
        ThirdImg: '',
        fourthImg: '',
        fifthImg: '',
        sumImg1:require('../../assets/images/gibraltar-detail-3.png'),
        projectsumHeadingmain: 'Discover',
        projectsumPara: '',
        projectsumHeading1:"FAQ",
        sumImg2:require('../../assets/images/gibraltar-detail-4.png'),
        projectsumHeading2:"Careers",
        sumImg3:require('../../assets/images/gibraltar-detail-5.png'),
        sumImg4: '',
        sumImg5: '',
    },
    {
        id : 34,
        casename: 'izy-host',
        heading : "IzyHost",
        project_summary: true,
        web_project: true,
        clientwork_para: 'Discover seamless web hosting solutions with IzyHost.com, where reliability and simplicity converge. Elevate your online presence effortlessly, benefit from top-notch performance, and unlock a hassle-free hosting experience. Trust IzyHost.com to provide the perfect foundation for your digital success.',
        bannerImg: require('../../assets/images/izyhost-banner.png'),
        bannerColor: "#e9f4ff",
        text_direction: "left",
        banner_white: 'white',
        text_color: 'text-dark-200',
        bannerHeading:"IzyHost",
        bannerDetail:"Revolutionizing Real Estate Search & Transactions with Hous",
        color_pallete:[
            {
                id: 1,
                color_Code: "#FFD000",        
            },
            {
                id: 2,
                color_Code: "#0E0E0E",        
            },
            {
                id: 3,
                color_Code: "#ffffff",        
            },
        ],
        mainPageImg:require('../../assets/images/izyhost-detailImg-1.png'),
        secondImgHeading:"About Us",
        fourthImgHeading: '',
        fifthImgHeading: '',
        ThirdImgHeading: '',
        secondImg:require('../../assets/images/izyhost-detailImg-2.png'),
        ThirdImg: '',
        fourthImg: '',
        fifthImg: '',
        sumImg1:require('../../assets/images/izyhost-detailImg-3.png'),
        projectsumHeadingmain: 'Project Summary',
        projectsumPara: 'IzyHost.com revolutionizes web hosting by offering streamlined and reliable solutions for individuals and businesses. With a focus on simplicity and top-notch performance, our platform provides a hassle-free hosting experience. Embrace a digital future with confidence as IzyHost.com becomes your trusted partner in establishing and maintaining a robust online presence.',
        projectsumHeading1:"Properties",
        sumImg2:require('../../assets/images/izyhost-detailImg-4.png'),
        projectsumHeading2:"Contact Us",
        sumImg3:require('../../assets/images/izyhost-detailImg-5.png'),
        sumImg4: '',
        sumImg5: '',
    },
];

export default detailpageApi